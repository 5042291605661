import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  BackTop,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    window.updateCarousel();
    window.reRenderRangeSlider();
    var stock = this.props.stock;
    fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
    //if (
    //  this.props.wishlistproducts.filter(function (e) {
    //    return e._id === stock._id;
    //  }).length === 1
    //) {
    //  this.setState({ isInWishList: true });
    //}
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "black",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];

    const test = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <div
          className="wrap-body-inner desktop-view"
          id="NewVehicles"
          style={{
            backgroundColor: "black",
            paddingTop: "3em",
            paddingBottom: "3em",
            marginTop: "-2em",
          }}
        >
          <div>
            {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
            {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          </div>
          <div
            className="row"
            style={{
              backgroundImage:
                "url(" +
                `${process.env.PUBLIC_URL}/images/gwm-ranges-background-image.webp` +
                ")",
              backgroundSize: "100vw",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              height: "100%",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                paddingTop: "2em",
                fontSize: "30px",
                lineHeight: "0.2em",
                color: "white",
                marginBottom: "1em",
                maxWidth: "90em",
              }}
            >
              Range of Vehicles
            </h1>
            {/* <img
                src={`${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png`}
                alt=''
                style={{ width: "100%", margin: "auto", position: "absolute", top: 0, zIndex: 1 }}
              /> */}
            <Slider {...test}>
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/steed-5-single-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steed-5-single-main.png`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px", // ← Nie seker of hierdie code reg is nie maar is al hoe ek die grys blok in die middel kon kry
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        GWM Steed 5 Single Cab
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#B0B6BC",
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/steed-5-single-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          View deals
                        </h4>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href={
                          "https://www.gwm.co.za/10_DEC_21_Steed_5_Single_Cab_Brochure.pdf"
                        }
                        target="_blank"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Download brochure
                        </h4>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/steed-5-single-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Book a test drive
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/steed-5-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steed-5-double-main.png`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px", // ← Nie seker of hierdie code reg is nie maar is al hoe ek die grys blok in die middel kon kry
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        GWM Steed 5 Double Cab
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#B0B6BC",
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/steed-5-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          View deals
                        </h4>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href={
                          "https://www.gwm.co.za/10_DEC_21_Steed_5_Double_Cab_Brochure.pdf"
                        }
                        target="_blank"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Download brochure
                        </h4>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/steed-5-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Book a test drive
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link to={`/p-series-commercial-single-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/p-series-commercial-single-cab-main.png`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px", // ← Nie seker of hierdie code reg is nie maar is al hoe ek die grys blok in die middel kon kry
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        GWM P-Series Commercial Single Cab
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#B0B6BC",
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link to={`/p-series-commercial-single-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          View deals
                        </h4>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href={
                          "https://www.gwm.co.za/Haval_GWM_P_Series_Brochure_Commercial_SC_7th_March_2022.pdf"
                        }
                        target="_blank"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Download brochure
                        </h4>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link to={`/p-series-commercial-single-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Book a test drive
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link to={`/p-series-commercial-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/p-series-commercial-double-cab-main.png`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px", // ← Nie seker of hierdie code reg is nie maar is al hoe ek die grys blok in die middel kon kry
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        GWM P-Series Commercial Double Cab
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#B0B6BC",
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link to={`/p-series-commercial-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          View deals
                        </h4>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href={
                          "https://www.gwm.co.za/Haval_GWM_P_Series_Brochure_Commercial_DC_7th_March_2022.pdf"
                        }
                        target="_blank"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Download brochure
                        </h4>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link to={`/p-series-commercial-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Book a test drive
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link to={`/p-series-passenger-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/p-series-passenger-double-cab-main.png`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px", // ← Nie seker of hierdie code reg is nie maar is al hoe ek die grys blok in die middel kon kry
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        GWM P-Series Passenger Double Cab
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#B0B6BC",
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link to={`/p-series-passenger-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          View deals
                        </h4>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href={
                          "https://www.gwm.co.za/HAVAL_GWM_P_Series_Passenger_DC_Digital_Brochure.pdf"
                        }
                        target="_blank"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Download brochure
                        </h4>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link to={`/p-series-passenger-double-cab`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Book a test drive
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/p-series-ltd-double`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/p-series-ltd-double-cab-main.png`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px", // ← Nie seker of hierdie code reg is nie maar is al hoe ek die grys blok in die middel kon kry
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        GWM P-Series LTD Double Cab
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#B0B6BC",
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/p-series-ltd-double`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          View deals
                        </h4>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href={
                          "https://www.dealassist.gwm.co.za/GWM%20P-SERIES%20LTD%20BROCHURE%20JULY%202023.pdf"
                        }
                        target="_blank"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Download brochure
                        </h4>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/p-series-ltd-double`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                          width={30}
                          height={30}
                          alt=""
                          style={{
                            marginTop: "2em",
                          }}
                        />
                        <h4
                          style={{
                            fontSize: "17px",
                            textTransform: "none",
                            color: "white",
                            marginTop: "-0.3em",
                          }}
                        >
                          Book a test drive
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Slider>
          </div>
        </div>
        <div className="wrap-body-inner mobile-view">
          {/* <div> */}
          {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
          {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          {/* </div> */}
          <div
            style={{ height: "45em" }}
          // className="product product-grid product-grid-2 stock  p-t-sm-35 m-b-lg-20 "
          // style={{
          //   maxWidth: "90em",
          //   // backgroundColor: "black",
          //   margin: "auto",
          //   marginTop: "-1.2em",
          //   paddingTop: "3em",
          //   paddingBottom: "1em",
          // }}
          >
            <div
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/gwm-ranges-background-image.webp` +
                  ")",
                backgroundPosition: "center",
                height: "24em",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  lineHeight: "0.2em",
                  color: "white",
                  margin: "auto",
                  marginBottom: "1em",
                }}
              >
                Range of Vehicles
              </h1>
              {/* <img
                src={`${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png`}
                alt=''
                style={{ width: "100%", margin: "auto", position: "absolute", top: 0, zIndex: 1 }}
              /> */}
              <Slider {...test}>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link
                          className="product-img"
                          to={`/steed-5-single-cab`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steed-5-single-main.png`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      //
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          GWM Steed 5 Single Cab
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        backgroundColor: "#B0B6BC",
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <Link
                          className="product-img"
                          to={`/steed-5-single-cab`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24} style={{}}>
                        <a
                          href={
                            "https://www.gwm.co.za/10_DEC_21_Steed_5_Single_Cab_Brochure.pdf"
                          }
                          target="_blank"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link
                          className="product-img"
                          to={`/steed-5-single-cab`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>

                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link
                          className="product-img"
                          to={`/steed-5-double-cab`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steed-5-double-main.png`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          GWM Steed 5 Double Cab
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        backgroundColor: "#B0B6BC",
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <Link
                          className="product-img"
                          to={`/steed-5-double-cab`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a
                          href={
                            "https://www.gwm.co.za/10_DEC_21_Steed_5_Double_Cab_Brochure.pdf"
                          }
                          target="_blank"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link
                          className="product-img"
                          to={`/steed-5-double-cab`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/p-series-commercial-single-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/p-series-commercial-single-cab-main.png`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "1em",
                          }}
                        >
                          GWM P-Series Commercial Single Cab
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        backgroundColor: "#B0B6BC",
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/p-series-commercial-single-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a
                          href={
                            "https://www.gwm.co.za/Haval_GWM_P_Series_Brochure_Commercial_SC_7th_March_2022.pdf"
                          }
                          target="_blank"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link to={`/p-series-commercial-single-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/p-series-commercial-double-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/p-series-commercial-double-cab-main.png`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "1em",
                          }}
                        >
                          GWM P-Series Commercial Double Cab
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        backgroundColor: "#B0B6BC",
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/p-series-commercial-double-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a
                          href={
                            "https://www.gwm.co.za/Haval_GWM_P_Series_Brochure_Commercial_DC_7th_March_2022.pdf"
                          }
                          target="_blank"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link to={`/p-series-commercial-double-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/p-series-passenger-double-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/p-series-passenger-double-cab-main.png`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "1em",
                          }}
                        >
                          GWM P-Series Passenger Double Cab
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        backgroundColor: "#B0B6BC",
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/p-series-passenger-double-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a
                          href={
                            "https://www.gwm.co.za/HAVAL_GWM_P_Series_Passenger_DC_Digital_Brochure.pdf"
                          }
                          target="_blank"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link to={`/p-series-passenger-double-cab`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link
                          className="product-img"
                          to={`/p-series-ltd-double`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/p-series-ltd-double-cab-main.png`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      //
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          GWM P-Series LTD Double Cab
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        backgroundColor: "#B0B6BC",
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <Link
                          className="product-img"
                          to={`/p-series-ltd-double`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24} style={{}}>
                        <a
                          href={
                            "https://www.dealassist.gwm.co.za/GWM%20P-SERIES%20LTD%20BROCHURE%20JULY%202023.pdf"
                          }
                          target="_blank"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link
                          className="product-img"
                          to={`/p-series-ltd-double`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Slider>
            </div>
          </div>
        </div>
        <BackTop />
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);
