import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { range } from "lodash";
import StockList from "./StockListNew";
import { fetchFiltredStock, turnOffHomefilter } from "../../redux/actions";
import { connect } from "react-redux";

import CurrencyFormat from "react-currency-format";
import $ from "jquery";
import {
  Checkbox,
  Select,
  Divider,
  Slider,
  Switch,
  Collapse,
  Button,
  Popover,
  Row,
  Col
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class StockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageSize: 24,
      priceSort: "Low to High",
      displayType: "cards",
      fetchedStock: props.fetchedStock,
      renderedStock: {},
      buttonSearchClicked: false,
      years: [],
      modelYear: "",
      make: "",
      series: "",
      currentSort: "default",
      mileage: "",
      condition: "",
      bodyType: "",
      transmission: "",
      resetValue: false,
      vehicleDrive: "",
      //features: "",
      branch: "",
      extColour: "",
      fuelType: "",
      onSale: undefined,
      modelDesc: "",
      webVisible: "",
      checked: true,
      priceRange: "R5,000 - R999,000",
      mileageRange: "1,000km -  300,000km",
      yearRange: "2000 -  2022",
    };
    this.setCarYear = this.setCarYear.bind(this);
    this.setCarMake = this.setCarMake.bind(this);
    this.setCarModel = this.setCarModel.bind(this);
    this.setCarEngineCapacity = this.setCarEngineCapacity.bind(this);
    this.setCarMileage = this.setCarMileage.bind(this);
    this.setCarCondition = this.setCarCondition.bind(this);
    this.setCarBody = this.setCarBody.bind(this);
    this.setCarTransmission = this.setCarTransmission.bind(this);
    this.setCarDriveTrain = this.setCarDriveTrain.bind(this);
    this.setCarFeatures = this.setCarFeatures.bind(this);
    this.setCarBranch = this.setCarBranch.bind(this);
    this.setCarColour = this.setCarColour.bind(this);
    this.setCarFuelType = this.setCarFuelType.bind(this);
    this.setCarOnSale = this.setCarOnSale.bind(this);
    this.renderStock = this.renderStock.bind(this);
  }
  componentWillMount = () => {
    fetch("https://www.vcsappcloud.com/mtgroup/api/evolve/findyears", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({ years: result.years });
    });
  };
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };
  resetValue = () => {
    this.setState({ resetValue: !this.state.resetValue });
  };
  resetAll = () => {
    this.setState({ resetValue: !this.state.resetValue });
  };
  onChange = (e) => {
    console.log("checked = ", e.target.checked);
    this.setState({
      checked: e.target.checked,
    });
  };
  buttonSearchClicked() {
    if (this.state !== null) {
      var {
        modelYear,
        make,
        series,
        modelDesc,
        mileage,
        condition,
        bodyType,
        transmission,
        vehicleDrive,
        features,
        branch,
        extColour,
        fuelType,
        onSale,
        priceRange,
        mileageRange,
        yearRange,
      } = this.state;
      var filter = {};
      priceRange = $("#priceslider").val();
      mileageRange = $("#mileageslider").val();
      yearRange = $("#yearslider").val();

      filter = {
        modelYear,
        make,
        series,
        modelDesc,
        mileage,
        condition,
        bodyType,
        transmission,
        vehicleDrive,
        features,
        branch,
        extColour,
        fuelType,
        onSale,
        priceRange,
        mileageRange,
        yearRange,
      };
      this.props.fetchFiltredStock(filter);
      this.setState({ mileageRange });
      this.setState({ priceRange });
      this.setState({ yearRange });
    }
    this.setState({ buttonSearchClicked: true });
    this.props.turnOffHomefilter();
  }
  setCarYear = (value) => {
    this.setState({ modelYear: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    (onSale = onSale),
      (filter = {
        make,
        modelYear: value,
        series,
        modelDesc,
        mileage,
        condition,
        bodyType,
        transmission,
        vehicleDrive,
        features,
        branch,
        extColour,
        fuelType,
        priceRange,
        mileageRange,
        yearRange,
        onSale,
      });
    //this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
    //this.props.turnOffHomefilter();
  };

  setCarMake(value) {
    this.setState({ make: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make: value,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarModel(value) {
    this.setState({ series: value });
    var {
      priceRange,
      yearRange,
      mileageRange,
      modelYear,
      make,

      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;

    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series: value,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      yearRange,
      mileageRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarEngineCapacity(value) {
    this.setState({ modelDesc: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,

      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;

    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc: value,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarMileage(value) {
    this.setState({ mileage: value });
    var { priceRange } = this.state;
    var { mileageRange } = this.state;
    var { yearRange } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    filter = {
      mileage: value,
      priceRange,
      mileageRange,
      yearRange,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarCondition(value) {
    this.setState({ condition: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,

      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;

    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition: value,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarMileage(value) {
    this.setState({ mileage: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,

      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;

    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage: value,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarBody(value) {
    this.setState({ bodyType: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,

      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;

    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType: value,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarTransmission(value) {
    this.setState({ transmission: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,

      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;

    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission: value,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarDriveTrain(value) {
    this.setState({ vehicleDrive: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,

      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;

    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive: value,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarFeatures(value) {
    this.setState({ features: value });
    var { priceRange } = this.state;
    var { mileageRange } = this.state;
    var { yearRange } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    filter = {
      features: value,
      priceRange,
      mileageRange,
      yearRange,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarBranch(value) {
    this.setState({ branch: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,

      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch: value,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarColour(value) {
    this.setState({ extColour: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,

      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour: value,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }

  setCarFuelType(value) {
    this.setState({ fuelType: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType: value,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarOnSale(value) {
    this.setState({ onSale: !this.state.onSale });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale: !this.state.onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setActivePage(activePage) {
    this.setState({ activePage: activePage });
  }
  setPageSize(size) {
    this.setState({ activePage: 1, pageSize: size });
  }
  setPriceSort(stock) {
    stock.sort((a, b) => (a.price > b.price ? 1 : -1));
  }
  setDisplayType(displayType) {
    this.setState({ displayType });
  }

  getallStock() {
    this.props.fetchFiltredStock({});
  }
  viewAllStock() {
    window.location.reload();
    //document.documentElement.scrollTop = 0;
    this.props.fetchFiltredStock({});
  }
  onSortChangeFromLow = () => {
    const { currentSort } = this.state;
    //let nextSort;

    //if (currentSort === "down") nextSort = "up";
    //else if (currentSort === "up") nextSort = "default";
    //else if (currentSort === "default") nextSort = "down";

    this.setState({
      currentSort: "up",
    });
  };
  onSortChangeFromHigh = () => {
    const { currentSort } = this.state;
    //let nextSort;

    //if (currentSort === "down") nextSort = "up";
    //else if (currentSort === "up") nextSort = "default";
    //else if (currentSort === "default") nextSort = "down";

    this.setState({
      currentSort: "down",
    });
  };
  renderStock() {
    const { currentSort } = this.state;
    const sortTypes = {
      up: {
        class: "sort-up",
        fn: (a, b) => a.price - b.price,
      },
      down: {
        class: "sort-down",
        fn: (a, b) => b.price - a.price,
      },
      default: {
        class: "sort",
        //fn: (a, b) => a,
        fn: (a, b) => a.price - b.price,
      },
    };
    var {
      buttonSearchClicked,
      pageSize,
      priceSort,
      activePage,
      displayType,
    } = this.state;
    var { fetchedStock, stockList, homefilterActivated } = this.props;
    var stock = fetchedStock.length !== 0 ? fetchedStock : stockList;

    /**this condition is if the user search for something from the  search button in stock cntainer and no results are found
     * or enters to stock listing throught the home search button and no results found */
    if (
      fetchedStock.length === 0 &&
      (buttonSearchClicked || homefilterActivated)
    ) {
      return (
        <div className='banner-item banner-2x no-bg '>
          <h2 className='f-weight-300'>
            <i className='fa fa-search m-r-lg-10'> </i>No RESULTS
          </h2>
          <a
            className='ht-btn ht-btn-default ht-btn-2x m-t-lg-35'
            onClick={() => this.viewAllStock()}
          >
            View all stock
          </a>
        </div>
      );
    } else {
      return (
        <StockList
          displayType={displayType}
          stocklist={stock
            .sort(sortTypes[currentSort].fn)
            .slice(
              (activePage - 1) * pageSize,
              (activePage - 1) * pageSize + pageSize
            )}
        />
      );
    }
  }
  scrollOnclick() {
    window.location.reload();
    document.documentElement.scrollTop = 0;
  }
  render() {
    var { fetchedStock, stockList, filters, homefilterActivated } = this.props;
    var { pageSize, priceSort, buttonSearchClicked } = this.state;
    const {
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
      priceRange,
      mileageRange,
      yearRange,
    } = filters;
    const duplicateCheck = [];
    var stock = fetchedStock.length !== 0 ? fetchedStock : stockList;
    /**stock list will be empty if a search returns no result */
    if (fetchedStock.length === 0 && buttonSearchClicked) stock = {};
    /**returns a number to know the number of pages */
    const table = range(1, Math.ceil(stock.length / pageSize) + 1, 1);

    /**if I am in stock container And I was in home containers , and I already had a filter then the slider should follow the previous slider
     *  filter that was setted in state */
    if (!homefilterActivated && buttonSearchClicked) {
      var priceIntervall = this.state.priceRange;
      var min = 1000 * priceIntervall.slice(1, priceIntervall.indexOf(","));
      var max =
        1000 *
        priceIntervall.slice(
          priceIntervall.indexOf("-") + 3,
          priceIntervall.indexOf(",", priceIntervall.indexOf("-"))
        );
      window.reRenderRangeSliderOther(min, max);
    }
    if (!homefilterActivated && buttonSearchClicked) {
      var yearIntervall = this.state.yearRange;
      var min = 1 * yearIntervall.slice(1, yearIntervall.indexOf(" -  ") - 0);
      var max = 1 * yearIntervall.slice(yearIntervall.indexOf("-") + 2);
      window.reRenderRangeSliderOtherYear(min, max);
    }
    if (!homefilterActivated && buttonSearchClicked) {
      var priceIntervall = this.state.mileageRange;
      var min = 1000 * priceIntervall.slice(1, priceIntervall.indexOf(","));
      var max =
        1000 *
        priceIntervall.slice(
          priceIntervall.indexOf("-") + 3,
          priceIntervall.indexOf(",", priceIntervall.indexOf("-"))
        );
      window.reRenderRangeSliderOtherMileage(min, max);
    }
    /**If I was in stock containers and I did not changed yet the slider in stock container then the slider should be as in filters */
    if (homefilterActivated && !buttonSearchClicked) {
      min = 1000 * priceRange.slice(1, priceRange.indexOf(","));
      max =
        1000 *
        priceRange.slice(
          priceRange.indexOf("-") + 3,
          priceRange.indexOf(",", priceRange.indexOf("-"))
        );
      window.reRenderRangeSliderOther(min, max);
    } else if (!buttonSearchClicked) {
      window.reRenderRangeSlider();
    }
    if (homefilterActivated && !buttonSearchClicked) {
      var yearIntervall = this.state.yearRange;
      var min = 1 * yearIntervall.slice(1, yearIntervall.indexOf(" -  ") - 0);
      var max = 1 * yearIntervall.slice(yearIntervall.indexOf("-") + 2);
      window.reRenderRangeSliderOtherYear(min, max);
    } else if (!buttonSearchClicked) {
      window.reRenderRangeSliderYear();
    }
    if (!buttonSearchClicked) {
      window.reRenderRangeSliderMileage();
    }

    const carsToRender =
      fetchedStock.length > 0
        ? stock.filter((stock) => stock.webVisible && stock.condition === "NEW" && stock.megaDealers === "KIA")
        : stockList;
    const visibleCars = carsToRender.length;

    return (
      <section className='m-t-lg-30 m-t-xs-0' style={{ margin: "auto" }}>

        <div style={{ background: "#e40401", height: "5em", width: "100vw", marginTop: window.isPhone ? "4em" : "9.5em", paddingLeft: window.isPhone ? "1em" : "7em" }}>
          <h3 className='f-weight-600 f-30' style={{ paddingTop: "0.6em", color: "white", maxWidth: "90em", margin: "auto" }}>
            GWM Range</h3></div>


        <div
          style={{
            background: "white",
            padding: 0,

          }}
        >
          <div
          // style={{ backgroundColor: "rgb(242, 244, 244)" }}
          >
            <Row
              justify='center'
              style={{
                margin: "auto",
              }}
            >
              <Col span={24}>
                <div className="video-overlay-1">
                </div>

                <img src={`${process.env.PUBLIC_URL}/images/new-banner.jpg`} style={{ width: "100vw", height: window.isPhone ? "16em" : "20em", objectFit: "cover" }}

                />


              </Col>
              {/* <Col span={12}>
                </Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt='logo'
                  style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
                /> */}
            </Row>
          </div>
        </div>
        <div style={{ margin: "auto", marginTop: "0em", padding: window.isPhone ? "" : "3em", paddingTop: "1em" }}>

          <div className='col-sm-12 col-md-12 col-lg-12' style={{ marginBottom: "4em", marginTop: "2em" }}>

            <div className='product product-grid product-grid-2 stock'>
              <div className='clearfix'></div>
              <div className='col-sm-12 col-md-12 col-lg-4' >
                <div className='product-item hover-img ' >

                  <a
                    className='product-img'
                    href={`/gwm/steed-5-single-cab`}
                  >
                    <img
                      style={{ objectFit: "contain", width: "20em", height: "12em", backgroundColor: "#d3d9df" }}
                      src={`${process.env.PUBLIC_URL}/images/steed-5-single-main.png`}

                      alt=''
                    />
                  </a>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "0em", fontSize: "17px", color: "#8f8f8f" }}
                    >
                      <a
                        href={`/gwm/steed-5-single-cab`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          GWM Steed 5 Single Cab <i
                            class='fa fa-long-arrow-right'
                            aria-hidden='true'
                            style={{ marginLeft: "0.2em" }}
                          ></i>

                        </span>
                      </a>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-4' >
                <div className='product-item hover-img ' >

                  <a
                    className='product-img'
                    href={`/gwm/steed-5-double-cab`}
                  >
                    <img
                      style={{ objectFit: "contain", width: "20em", height: "12em", backgroundColor: "#d3d9df" }}
                      src={`${process.env.PUBLIC_URL}/images/steed-5-double-main.png`}

                      alt=''
                    />
                  </a>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "0em", fontSize: "17px", color: "#8f8f8f" }}
                    >
                      <a
                        href={`/gwm/steed-5-double-cab`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          GWM Steed 5 Double Cab <i
                            class='fa fa-long-arrow-right'
                            aria-hidden='true'
                            style={{ marginLeft: "0.2em" }}
                          ></i>

                        </span>
                      </a>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-4' >
                <div className='product-item hover-img ' >

                  <a
                    className='product-img'
                    href={`/gwm/p-series-commercial-single-cab`}
                  >
                    <img
                      style={{ objectFit: "contain", width: "20em", height: "12em", backgroundColor: "#d3d9df" }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-commercial-single-cab-main.png`}

                      alt=''
                    />
                  </a>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "0em", fontSize: "17px", color: "#8f8f8f" }}
                    >
                      <a
                        href={`/gwm/p-series-commercial-single-cab`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          GWM P-Series Commercial Single Cab <i
                            class='fa fa-long-arrow-right'
                            aria-hidden='true'
                            style={{ marginLeft: "0.2em" }}
                          ></i>

                        </span>
                      </a>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-4' >
                <div className='product-item hover-img ' >

                  <a
                    className='product-img'
                    href={`/gwm/p-series-commercial-double-cab`}
                  >
                    <img
                      style={{ objectFit: "contain", width: "20em", height: "12em", backgroundColor: "#d3d9df" }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-commercial-double-cab-main.png`}

                      alt=''
                    />
                  </a>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "0em", fontSize: "17px", color: "#8f8f8f" }}
                    >
                      <a
                        href={`/gwm/p-series-commercial-double-cab`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          GWM P-Series Commercial Double Cab <i
                            class='fa fa-long-arrow-right'
                            aria-hidden='true'
                            style={{ marginLeft: "0.2em" }}
                          ></i>

                        </span>
                      </a>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-4' >
                <div className='product-item hover-img ' >

                  <a
                    className='product-img'
                    href={`/gwm/p-series-passenger-double-cab`}
                  >
                    <img
                      style={{ objectFit: "contain", width: "20em", height: "12em", backgroundColor: "#d3d9df" }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-passenger-double-cab-main.png`}

                      alt=''
                    />
                  </a>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "0em", fontSize: "17px", color: "#8f8f8f" }}
                    >
                      <a
                        href={`/gwm/p-series-passenger-double-cab`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          GWM P-Series Passenger Double Cab <i
                            class='fa fa-long-arrow-right'
                            aria-hidden='true'
                            style={{ marginLeft: "0.2em" }}
                          ></i>

                        </span>
                      </a>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}
/**fetchedStock is the reducer state for the stock fetched from the API ,
 *  and homefilterActivated is to know if the user entred this page throught the home filter or not */
function mapStateToProps({ fetchedStock, homefilterActivated }) {
  return { fetchedStock, homefilterActivated };
}

export default connect(mapStateToProps, {
  fetchFiltredStock,
  turnOffHomefilter,
})(StockContainer);
