import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "./client";
import { Col, Row, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);
  const [sliderRef, setSliderRef] = useState(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,

        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      "name": author->name,
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div className="content">
      <div className="desktop-view">
        <Slider ref={setSliderRef} {...settings}>
          {allPostsData &&
            allPostsData.map((post, index) =>
              post.name === "GWM" ? (
                <Col
                  style={{
                    width: "22em",
                    padding: "0.5em",
                    height: "26em",
                    marginTop: "1em",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      padding: "1em",
                      height: "26em",
                      // background:
                      //   "linear-gradient(180deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 100%)",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "15em",
                        zIndex: -9999,
                        borderRadius: "0em",
                      }}
                      src={post.mainImage.asset.url}
                      alt=""
                    />{" "}
                  </div>
                  <h2
                    className="main-blogs-header"
                    style={{
                      fontSize: "13px",
                      fontWeight: 700,
                      padding: "3em",
                      paddingTop: "2.3em",
                      paddingLeft: "0.5em",
                      margin: "auto",
                      maxWidth: "100%",
                      position: "absolute",
                      paddingTop: "2em",
                      top: "0em",
                      color: window.isPhone ? "white" : "black",
                      //background:
                      //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                      marginTop: "17.5em",
                      fontFamily: "Arial",
                      lineHeight: "1em",
                    }}
                  >
                    {post.name}
                  </h2>
                  <h2
                    className="main-blogs-header"
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      padding: "3em",
                      paddingLeft: "0.5em",
                      margin: "auto",
                      maxWidth: "100%",
                      position: "absolute",
                      paddingTop: "2em",
                      top: "0em",
                      color: window.isPhone ? "white" : "black",
                      //background:
                      //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                      marginTop: "17.5em",

                      lineHeight: "1.3em",
                      // backgroundColor: "black"
                    }}
                  >
                    {post.title}
                  </h2>
                  <Link to={"/" + post.slug.current} key={post.slug.current}>
                    <h2
                      className="main-blogs-header"
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "2em",
                        left: 0,
                        paddingLeft: "0.5em",
                        margin: "auto",
                        maxWidth: "100%",
                        position: "absolute",

                        top: "8em",
                        color: window.isPhone ? "white" : "black",
                        //background:
                        //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                        marginTop: "14.5em",

                        lineHeight: "1em",
                      }}
                    >
                      Read more <SwapRightOutlined />
                    </h2>
                  </Link>
                </Col>
              ) : null
            )}{" "}
        </Slider>
      </div>
      <div className="mobile-view">
        <Slider ref={setSliderRef} {...settings}>
          {allPostsData &&
            allPostsData.map((post, index) =>
              post.name === "GWM" ? (
                <Col
                  style={{
                    width: "22em",
                    padding: "0.5em",
                    height: "25em",
                    marginTop: "1em",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 100%)",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "cover",
                        //width: "22em",
                        height: "25em",
                        zIndex: -9999,
                        borderRadius: "0em",
                        opacity: 0.5,
                      }}
                      src={post.mainImage.asset.url}
                      alt=""
                    />{" "}
                  </div>
                  <h2
                    className="main-blogs-header"
                    style={{
                      fontSize: "13px",
                      fontWeight: 700,
                      padding: "3em",
                      paddingTop: "2.3em",
                      paddingLeft: "0.5em",
                      margin: "auto",
                      maxWidth: "100%",
                      position: "absolute",
                      paddingTop: "2em",
                      top: "-2em",
                      color: window.isPhone ? "white" : "black",
                      textAlign: "left",
                      //background:
                      //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                      marginTop: "15.5em",
                      fontFamily: "Arial",
                      lineHeight: "1em",
                    }}
                  >
                    {post.name}
                  </h2>
                  <h2
                    className="main-blogs-header"
                    style={{
                      fontSize: "15px",
                      fontWeight: 500,
                      padding: "3em",
                      paddingLeft: "0.5em",
                      textAlign: "left",
                      margin: "auto",
                      maxWidth: "100%",
                      position: "absolute",
                      paddingTop: "2.5em",
                      top: "-2em",
                      color: window.isPhone ? "white" : "black",
                      //background:
                      //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                      marginTop: "15.5em",
                      fontFamily: "Arial",
                      lineHeight: "1.3em",
                    }}
                  >
                    {post.title}
                  </h2>
                  <Link to={"/" + post.slug.current} key={post.slug.current}>
                    <h2
                      className="main-blogs-header"
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "2em",
                        right: 0,
                        paddingLeft: "0.5em",
                        margin: "auto",
                        maxWidth: "100%",
                        position: "absolute",

                        top: "4em",
                        color: window.isPhone ? "white" : "black",
                        //background:
                        //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                        marginTop: "15.5em",
                        fontFamily: "Arial",
                        lineHeight: "1em",
                      }}
                    >
                      Read more <SwapRightOutlined />
                    </h2>
                  </Link>
                </Col>
              ) : null
            )}{" "}
        </Slider>
      </div>
    </div>
  );
}
