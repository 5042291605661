import React, { Component } from "react";
import { Row, Col, Divider, Input, Button } from "antd";
import { Link } from "react-router-dom";

class LegalNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="wrap-body-inner">
        <div>
          <div className="row">
            <div>
              <div
                id="home"
                className="home-div"
                style={{
                  //maxHeight: "100em",
                  marginTop: "12em",
                  //width: "100vw",
                }}
              >
                {" "}
                <Row
                  className="home-row"
                  style={{
                    maxWidth: "90em",
                    margin: "auto",
                  }}
                >
                  <Col
                    sm={24}
                    md={24}
                    style={{
                      margin: "auto",
                      textAlign: "left",
                      marginTop: "3em",
                      marginBottom: "3em",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "0em",
                        color: "#3F3F3F",
                        fontWeight: 700,
                        fontSize: "30px",
                        padding: "30px",
                        paddingBottom: 20,
                        paddingTop: 0,
                        lineHeight: "1em",
                      }}
                    >
                      LEGAL NOTICE
                    </h2>

                    <div
                      style={{
                        margin: "auto",
                        fontWeight: 100,
                        color: "black",
                        fontWeight: 400,
                        fontSize: "17px",
                        maxWidth: "90em",
                        padding: "30px",
                        paddingTop: 0,
                        textAlign: "left",
                      }}
                    >
                      {/*Date:	*/}
                      DEFINITIONS
                      <br />
                      "We, us or our" means: <br />
                      <br />
                      1. Mega Theron Group (Pty) Ltd with Reg. № 2020/570691/07
                      and Trading As:
                      <br />
                      1.1. GWM & HAVAL Klerksdorp situated at 30 Leask Street,
                      Klerksdorp, 2571
                      <br />
                      1.2. GWM & HAVAL Potchefstroom situated at 33 Nelson
                      Mandela Drive, Potchefstroom, 2531
                      <br />
                      1.3. Kia & Mahindra Mahikeng situated at 2320 Aerodrome
                      Road, Mahikeng, 2735
                      <br />
                      1.4. GWM & HAVAL Mahikeng situated at Shop 42, The
                      Crossing, C/O First & Sekame St, Mahikeng 2735
                      <br />
                      1.5. GWM & HAVAL Lichtenburg situated at 4 Thabo Mbeki
                      Drive, Lichtenburg, 2740
                      <br />
                      <br />
                      <br />
                      2. Mega Theron Group (Pty) Ltd with Reg. № 2020/086396/07
                      and situated at 13 Joe Slovo Road, Freemanville,
                      Klerksdorp, 2571
                      <br />
                      <br />
                      3. MTG Klerksdorp (Pty) Ltd T/A Chery Klerksdorp with Reg.
                      № 2021/990251/07 and situated at C/O Bram Fischer & Leask
                      Street, Klerksdorp, 2571
                      <br />
                      <br />
                      "Our site or our website" means the site on which these
                      terms and conditions are displayed, including, but not
                      limited to the following websites (www.megatheron.co.za)
                      "You or your" means the person accessing or using our
                      site.
                      <br />
                      <br />
                      COPYRIGHT
                      <br />
                      <br />
                      You accept and agree that all content and materials on the
                      website are fully protected by copyrights, and any
                      additional proprietary rights and laws that may apply.
                      <br />
                      <br />
                      DISCLAIMER
                      <br />
                      <br />
                      The following terms apply to all user of this website. BY
                      ENTERING THE WEBSITE, YOU ACKNOWLEDGE AND ACCEPT THE
                      TERMS. We reserves the right to change this policy at any
                      time and for any reason, without prior warning. We
                      encourage you to view the terms and conditions every time
                      you access this website. All type of material,
                      information, software, products, offers and services
                      provided through the website is provided as it stands and
                      as it is available for your usage. The content is provided
                      without any warranties. The use of information on this
                      website is to enable for consulting purposes only. This
                      website may include links to other websites. These links
                      are provided to gain further information. This site may
                      link to third party websites that may contain information
                      or material that could be found inappropriate or
                      offensive. As these websites are not under the control of
                      Mega Theron Group or its agents, you acknowledge that we
                      are not responsible and cannot be held liable for the
                      content displayed on these websites. Furthermore, under no
                      circumstances shall Mega Theron Group or its agents be
                      liable for any damages that result from the use of, or
                      inability to use, this site.
                      <br />
                      <br />
                      RESTRICTIONS <br />
                      <br />
                      You may view, download and copy information and materials
                      available on this website solely for your personal use. As
                      a condition of use, you agree not to modify or revise any
                      of the material in any manner. No other use of the
                      materials or information is authorized. Any violations of
                      the foregoing may result in civil and/or criminal
                      liabilities.
                      <br />
                      <br />
                      PRIVACY
                      <br />
                      <br />
                      We do not disclose any personal information to any third
                      party’s unless we have your permission and/or consent.{" "}
                      <br />
                      <br />
                      CONTACT INFORMATION
                      <br />
                      <br />
                      1. General Email: info@megatheron.co.za
                      <br />
                      2. Mega Theron Group (Pty) Ltd T/A:
                      <br />
                      2.1. GWM & HAVAL Klerksdorp – (018) 518 0111
                      <br />
                      2.2. GWM & HAVAL Potchefstroom – (018) 297 3000
                      <br />
                      2.3. Kia & Mahindra Mahikeng – (018) 518 0999
                      <br />
                      2.4. GWM & HAVAL Mahikeng - (018) 519 0994
                      <br />
                      2.5. GWM & HAVAL Lichtenburg – (018) 518 0889
                      <br />
                      3. Mega Theron Group (Pty) Ltd – (018) 462 3759
                      <br />
                      4. MTG Klerksdorp (Pty) Ltd T/A Chery Klerksdorp – (018)
                      297 3000
                      <br />
                      <br />
                      Unless stated otherwise, this agreement incorporate the
                      entire agreement between you and Mega Theron Group in
                      regards to the website, and takes precedence over all
                      prior communication between you and Mega Theron Group in
                      relation to the website.
                    </div>
                    <Divider
                      orientation="right"
                      style={{ borderTopColor: "rgba(0, 0, 0, 0.25)" }}
                    >
                      <Link to="/home">
                        <Button
                          style={{
                            float: "right",
                            height: "2.7em",
                            width: "10em",
                            fontSize: "17px",
                            fontWeight: 500,
                            borderRadius: "3em",
                            color: "white",
                            border: "none",
                            marginTop: "1em",
                            marginBottom: "1em",
                            backgroundColor: "#E40401",
                            textShadow: "none",
                            boxShadow: "none",
                          }}
                        >
                          Back to Home
                        </Button>
                      </Link>
                    </Divider>
                  </Col>
                </Row>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNotice;
