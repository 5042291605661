import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { range } from "lodash";
import StockList from "./StockListPromotion";
import { fetchFiltredStock, turnOffHomefilter } from "../../redux/actions";
import { connect } from "react-redux";
import $ from "jquery";
import {
  Checkbox,
  Select,
  Divider,
  Slider,
  Switch,
  Collapse,
  Button,
  Popover
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class StockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageSize: 24,
      priceSort: "Low to High",
      displayType: "cards",
      fetchedStock: props.fetchedStock,
      renderedStock: {},
      buttonSearchClicked: false,
      years: [],
      modelYear: "",
      make: "",
      series: "",
      currentSort: "default",
      mileage: "",
      condition: "",
      bodyType: "",
      transmission: "",
      resetValue: false,
      vehicleDrive: "",
      //features: "",
      branch: "",
      extColour: "",
      fuelType: "",
      onSale: undefined,
      modelDesc: "",
      webVisible: "",
      checked: true,
      priceRange: "R5,000 - R999,000",
      mileageRange: "1,000km -  300,000km",
      yearRange: "2000 -  2022",
    };
    this.setCarYear = this.setCarYear.bind(this);
    this.setCarMake = this.setCarMake.bind(this);
    this.setCarModel = this.setCarModel.bind(this);
    this.setCarEngineCapacity = this.setCarEngineCapacity.bind(this);
    this.setCarMileage = this.setCarMileage.bind(this);
    this.setCarCondition = this.setCarCondition.bind(this);
    this.setCarBody = this.setCarBody.bind(this);
    this.setCarTransmission = this.setCarTransmission.bind(this);
    this.setCarDriveTrain = this.setCarDriveTrain.bind(this);
    this.setCarFeatures = this.setCarFeatures.bind(this);
    this.setCarBranch = this.setCarBranch.bind(this);
    this.setCarColour = this.setCarColour.bind(this);
    this.setCarFuelType = this.setCarFuelType.bind(this);
    this.setCarOnSale = this.setCarOnSale.bind(this);
    this.renderStock = this.renderStock.bind(this);
  }
  componentWillMount = () => {
    fetch("https://www.vcsappcloud.com/mtgroup/api/evolve/findyears", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({ years: result.years });
    });
  };
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };
  resetValue = () => {
    this.setState({ resetValue: !this.state.resetValue });
  };
  resetAll = () => {
    this.setState({ resetValue: !this.state.resetValue });
  };
  onChange = (e) => {
    console.log("checked = ", e.target.checked);
    this.setState({
      checked: e.target.checked,
    });
  };
  buttonSearchClicked() {
    if (this.state !== null) {
      var {
        modelYear,
        make,
        series,
        modelDesc,
        mileage,
        condition,
        bodyType,
        transmission,
        vehicleDrive,
        features,
        branch,
        extColour,
        fuelType,
        onSale,
        priceRange,
        mileageRange,
        yearRange,
      } = this.state;
      var filter = {};
      priceRange = $("#priceslider").val();
      mileageRange = $("#mileageslider").val();
      yearRange = $("#yearslider").val();

      filter = {
        modelYear,
        make,
        series,
        modelDesc,
        mileage,
        condition,
        bodyType,
        transmission,
        vehicleDrive,
        features,
        branch,
        extColour,
        fuelType,
        onSale,
        priceRange,
        mileageRange,
        yearRange,
      };
      this.props.fetchFiltredStock(filter);
      this.setState({ mileageRange });
      this.setState({ priceRange });
      this.setState({ yearRange });
    }
    this.setState({ buttonSearchClicked: true });
    this.props.turnOffHomefilter();
  }
  setCarYear = (value) => {
    this.setState({ modelYear: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    (onSale = onSale),
      (filter = {
        make,
        modelYear: value,
        series,
        modelDesc,
        mileage,
        condition,
        bodyType,
        transmission,
        vehicleDrive,
        features,
        branch,
        extColour,
        fuelType,
        priceRange,
        mileageRange,
        yearRange,
        onSale,
      });
    //this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
    //this.props.turnOffHomefilter();
  };

  setCarMake(value) {
    this.setState({ make: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make: value,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarModel(value) {
    this.setState({ series: value });
    var {
      priceRange,
      yearRange,
      mileageRange,
      modelYear,
      make,

      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;

    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series: value,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      yearRange,
      mileageRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarEngineCapacity(value) {
    this.setState({ modelDesc: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,

      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;

    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc: value,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarMileage(value) {
    this.setState({ mileage: value });
    var { priceRange } = this.state;
    var { mileageRange } = this.state;
    var { yearRange } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    filter = {
      mileage: value,
      priceRange,
      mileageRange,
      yearRange,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarCondition(value) {
    this.setState({ condition: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,

      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;

    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition: value,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarMileage(value) {
    this.setState({ mileage: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,

      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;

    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage: value,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarBody(value) {
    this.setState({ bodyType: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,

      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;

    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType: value,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarTransmission(value) {
    this.setState({ transmission: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,

      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;

    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission: value,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarDriveTrain(value) {
    this.setState({ vehicleDrive: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,

      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;

    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive: value,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarFeatures(value) {
    this.setState({ features: value });
    var { priceRange } = this.state;
    var { mileageRange } = this.state;
    var { yearRange } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    filter = {
      features: value,
      priceRange,
      mileageRange,
      yearRange,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarBranch(value) {
    this.setState({ branch: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,

      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch: value,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarColour(value) {
    this.setState({ extColour: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,

      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour: value,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }

  setCarFuelType(value) {
    this.setState({ fuelType: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType: value,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarOnSale(value) {
    this.setState({ onSale: !this.state.onSale });
    var {
      priceRange,
      mileageRange,
      yearRange,
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    modelYear = modelYear;
    make = make;
    series = series;
    modelDesc = modelDesc;
    mileage = mileage;
    condition = condition;
    bodyType = bodyType;
    transmission = transmission;
    vehicleDrive = vehicleDrive;
    features = features;
    branch = branch;
    extColour = extColour;
    fuelType = fuelType;
    onSale = onSale;
    filter = {
      make,
      modelYear,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale: !this.state.onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setActivePage(activePage) {
    this.setState({ activePage: activePage });
  }
  setPageSize(size) {
    this.setState({ activePage: 1, pageSize: size });
  }
  setPriceSort(stock) {
    stock.sort((a, b) => (a.price > b.price ? 1 : -1));
  }
  setDisplayType(displayType) {
    this.setState({ displayType });
  }

  getallStock() {
    this.props.fetchFiltredStock({});
  }
  viewAllStock() {
    window.location.reload();
    //document.documentElement.scrollTop = 0;
    this.props.fetchFiltredStock({});
  }
  onSortChangeFromLow = () => {
    const { currentSort } = this.state;
    //let nextSort;

    //if (currentSort === "down") nextSort = "up";
    //else if (currentSort === "up") nextSort = "default";
    //else if (currentSort === "default") nextSort = "down";

    this.setState({
      currentSort: "up",
    });
  };
  onSortChangeFromHigh = () => {
    const { currentSort } = this.state;
    //let nextSort;

    //if (currentSort === "down") nextSort = "up";
    //else if (currentSort === "up") nextSort = "default";
    //else if (currentSort === "default") nextSort = "down";

    this.setState({
      currentSort: "down",
    });
  };
  renderStock() {
    const { currentSort } = this.state;
    const sortTypes = {
      up: {
        class: "sort-up",
        fn: (a, b) => a.price - b.price,
      },
      down: {
        class: "sort-down",
        fn: (a, b) => b.price - a.price,
      },
      default: {
        class: "sort",
        //fn: (a, b) => a,
        fn: (a, b) => a.price - b.price,
      },
    };
    var {
      buttonSearchClicked,
      pageSize,
      priceSort,
      activePage,
      displayType,
    } = this.state;
    var { fetchedStock, stockList, homefilterActivated } = this.props;
    var stock = fetchedStock.length !== 0 ? fetchedStock : stockList;

    /**this condition is if the user search for something from the  search button in stock cntainer and no results are found
     * or enters to stock listing throught the home search button and no results found */
    if (
      fetchedStock.length === 0 &&
      (buttonSearchClicked || homefilterActivated)
    ) {
      return (
        <div className='banner-item banner-2x no-bg '>
          <h2 className='f-weight-300'>
            <i className='fa fa-search m-r-lg-10'> </i>No RESULTS
          </h2>
          <a
            className='ht-btn ht-btn-default ht-btn-2x m-t-lg-35'
            onClick={() => this.viewAllStock()}
          >
            View all stock
          </a>
        </div>
      );
    } else {
      return (
        <StockList
          displayType={displayType}
          stocklist={stock
            .sort(sortTypes[currentSort].fn)
            .slice(
              (activePage - 1) * pageSize,
              (activePage - 1) * pageSize + pageSize
            )}
        />
      );
    }
  }
  scrollOnclick() {
    window.location.reload();
    document.documentElement.scrollTop = 0;
  }
  render() {
    var { fetchedStock, stockList, filters, homefilterActivated } = this.props;
    var { pageSize, priceSort, buttonSearchClicked } = this.state;
    const {
      modelYear,
      make,
      series,
      modelDesc,
      mileage,
      condition,
      bodyType,
      transmission,
      vehicleDrive,
      features,
      branch,
      extColour,
      fuelType,
      onSale,
      priceRange,
      mileageRange,
      yearRange,
    } = filters;
    const duplicateCheck = [];
    var stock = fetchedStock.length !== 0 ? fetchedStock : stockList;
    /**stock list will be empty if a search returns no result */
    if (fetchedStock.length === 0 && buttonSearchClicked) stock = {};
    /**returns a number to know the number of pages */
    const table = range(1, Math.ceil(stock.length / pageSize) + 1, 1);

    /**if I am in stock container And I was in home containers , and I already had a filter then the slider should follow the previous slider
     *  filter that was setted in state */
    if (!homefilterActivated && buttonSearchClicked) {
      var priceIntervall = this.state.priceRange;
      var min = 1000 * priceIntervall.slice(1, priceIntervall.indexOf(","));
      var max =
        1000 *
        priceIntervall.slice(
          priceIntervall.indexOf("-") + 3,
          priceIntervall.indexOf(",", priceIntervall.indexOf("-"))
        );
      window.reRenderRangeSliderOther(min, max);
    }
    if (!homefilterActivated && buttonSearchClicked) {
      var yearIntervall = this.state.yearRange;
      var min = 1 * yearIntervall.slice(1, yearIntervall.indexOf(" -  ") - 0);
      var max = 1 * yearIntervall.slice(yearIntervall.indexOf("-") + 2);
      window.reRenderRangeSliderOtherYear(min, max);
    }
    if (!homefilterActivated && buttonSearchClicked) {
      var priceIntervall = this.state.mileageRange;
      var min = 1000 * priceIntervall.slice(1, priceIntervall.indexOf(","));
      var max =
        1000 *
        priceIntervall.slice(
          priceIntervall.indexOf("-") + 3,
          priceIntervall.indexOf(",", priceIntervall.indexOf("-"))
        );
      window.reRenderRangeSliderOtherMileage(min, max);
    }
    /**If I was in stock containers and I did not changed yet the slider in stock container then the slider should be as in filters */
    if (homefilterActivated && !buttonSearchClicked) {
      min = 1000 * priceRange.slice(1, priceRange.indexOf(","));
      max =
        1000 *
        priceRange.slice(
          priceRange.indexOf("-") + 3,
          priceRange.indexOf(",", priceRange.indexOf("-"))
        );
      window.reRenderRangeSliderOther(min, max);
    } else if (!buttonSearchClicked) {
      window.reRenderRangeSlider();
    }
    if (homefilterActivated && !buttonSearchClicked) {
      var yearIntervall = this.state.yearRange;
      var min = 1 * yearIntervall.slice(1, yearIntervall.indexOf(" -  ") - 0);
      var max = 1 * yearIntervall.slice(yearIntervall.indexOf("-") + 2);
      window.reRenderRangeSliderOtherYear(min, max);
    } else if (!buttonSearchClicked) {
      window.reRenderRangeSliderYear();
    }
    if (!buttonSearchClicked) {
      window.reRenderRangeSliderMileage();
    }

    const carsToRender =
      fetchedStock.length > 0
        ? stock.filter((stock) => stock.webVisible && stock.onSale && stock.megaDealers === "GWM")
        : stockList;
    const visibleCars = carsToRender.length;

    return (
      <section className='m-t-lg-30 m-t-xs-0' style={{ margin: "auto" }}>
        {" "}
        {/* <div style={{ background: "black", height: "5em", width: "100vw", marginTop: "8.5em", paddingLeft: "4em" }}>   <h3 className='f-weight-600 f-30' style={{ paddingTop: "0.6em", color: "black", maxWidth: "41em", margin: "auto" }}>
          NEW VEHICLES</h3></div> */}
        <div style={{ maxWidth: "90em", margin: "auto", marginTop: "0em", padding: window.isPhone ? "" : "3em", paddingTop: "1em", marginTop: "8.5em", }}>
          <div className='col-sm-12 col-md-12 col-lg-12' style={{}}>
            <div className='clearfix'></div>
            <div className='banner-item banner-bg-41 banner-1x color-inher ' style={{ height: "28em" }}>
              {/* <h5>BOOK A SERVICE</h5>
              <h3 className='f-weight-300'>
                <strong>TRUSTWORTHY </strong> CAR SERVICES
              </h3>
              <p>Car in need of a service? Book one now. </p>
              <span onClick={this.scrollOnclick}>
                <Link to='/bookService' className='ht-btn ht-btn-default'>
                  Book Now
                </Link>
              </span> */}
            </div>
            {/* <div
              style={{
                // background: "#201A20",
                borderRadius: "0.5em 0.5em 0 0",
                padding: "0em",
                width: "100%",
                color: "black",
                marginBottom: "-0.7em",
                textAlign: "left",
              }}
            >
              <h3 className='f-weight-300 f-25' style={{ color: "black" }}>
                <strong>SEARCH ALL KIA MAHIKENG PROMOTIONS</strong>
                <br />
                <span className='f-weight-300 f-18' style={{ color: "black", textTransform: "none" }}>
                  View a wide range of Used cars available from Kia Mahikeng.Explore all models and view our affordable Used Car Specials available online
                </span>
              </h3>

            </div> */}

            <div className='search-option' style={{ padding: 30, paddingLeft: 0, paddingRight: 0, marginTop: "0.5em", }}>

              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20' style={{}}>
                <button
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    height: "1em",
                    marginLeft: "-0.2em",
                    marginTop: "-1em",
                  }}
                  className='form-item'
                >
                  Years
                </button>
                <div
                  id='spanrangeyear'
                  className='slider-range-year m-b-lg-5 m-t-lg-10'
                ></div>
                <input style={{ color: "black" }}
                  id='yearslider'
                  type='text'
                  disabled
                  className='slider_amount_year'
                  value={
                    yearRange !== undefined && homefilterActivated
                      ? yearRange
                      : this.state.yearRange
                  }
                />
              </div>
              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20' style={{}}>
                <button
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    height: "1em",
                    marginLeft: "-0.2em",
                    marginTop: "-1em",
                  }}
                  className='form-item'
                >
                  Price
                </button>
                <div
                  id='spanrange'
                  className='slider-range m-b-lg-5 m-t-lg-10'
                ></div>
                <input style={{ color: "black" }}
                  id='priceslider'
                  type='text'
                  disabled
                  className='slider_amount'
                  value={
                    priceRange !== undefined && homefilterActivated
                      ? priceRange
                      : this.state.priceRange
                  }
                />
              </div>
              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20' style={{ display: "none" }}>
                <button
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    height: "1em",
                    marginLeft: "-0.2em",
                    marginTop: "-1em",
                  }}
                  className='form-item'
                >
                  Mileage
                </button>
                <div
                  id='spanrangemileage'
                  className='slider-range-mileage m-b-lg-5 m-t-lg-10'
                ></div>
                <input style={{ color: "black" }}
                  id='mileageslider'
                  type='text'
                  disabled
                  className='slider_amount_mileage'
                  value={
                    mileageRange !== undefined && homefilterActivated
                      ? mileageRange
                      : this.state.mileageRange
                  }
                />
              </div>

              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20' style={{}}>
                <Select
                  mode='multiple'
                  allowClear
                  // placement='topLeft'
                  // bordered={false}
                  style={{ width: "100%", border: "1px solid white", color: "black", background: "transparent" }}
                  defaultValue={
                    filters !== undefined &&
                      series !== undefined &&
                      series !== ""
                      ? series
                      : series
                  }
                  placeholder='Model'
                  onChange={this.setCarModel}
                >
                  {" "}
                  <Option value=''>All Models</Option>
                  {this.props.fetchedStock
                    .sort((a, b) => a.series.localeCompare(b.series))
                    .map((stock, index) => {
                      if (duplicateCheck.includes(stock.series)) return null;
                      duplicateCheck.push(stock.series);
                      return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                        <Option key={index} value={stock.series}>
                          {stock.series}
                        </Option>
                      ) : null;
                    })
                    .filter((e) => e)}
                </Select>
              </div>
              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20' style={{}}>
                <Select
                  mode='multiple'
                  allowClear
                  // placement='topLeft'
                  // bordered={false}
                  style={{ width: "100%", border: "1px solid white", color: "black", background: "transparent" }}
                  defaultValue={
                    filters !== undefined &&
                      modelDesc !== undefined &&
                      modelDesc !== ""
                      ? modelDesc
                      : modelDesc
                  }
                  placeholder='Derivative'
                  onChange={this.setCarEngineCapacity}
                >
                  <Option value=''>All Derivatives</Option>
                  {this.props.fetchedStock
                    .sort((a, b) => a.modelDesc.localeCompare(b.modelDesc))
                    .map((stock, index) => {
                      if (duplicateCheck.includes(stock.modelDesc))
                        return null;
                      duplicateCheck.push(stock.modelDesc);
                      return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                        <Option key={index} value={stock.modelDesc}>
                          {stock.modelDesc}
                        </Option>
                      ) : null;
                    })
                    .filter((e) => e)}
                </Select>
              </div>
              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20' style={{}}>
                <Popover placement="bottom" content={
                  <span>
                    <Select
                      mode='multiple'
                      allowClear
                      // placement='topLeft'
                      // bordered={false}
                      style={{ width: "15em", border: "1px solid rgb(6, 20, 31)", color: "rgb(6, 20, 31)", margin: "0.5em" }}
                      defaultValue={
                        filters !== undefined &&
                          extColour !== undefined &&
                          extColour !== ""
                          ? extColour
                          : extColour
                      }
                      placeholder='Colour'
                      onChange={this.setCarColour}
                    >
                      <Option value=''>All Colours</Option>
                      {this.props.fetchedStock
                        .sort((a, b) => a.extColour.localeCompare(b.extColour))
                        .map((stock, index) => {
                          if (duplicateCheck.includes(stock.extColour))
                            return null;
                          duplicateCheck.push(stock.extColour);
                          return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                            <Option key={index} value={stock.extColour}>
                              {stock.extColour}
                            </Option>
                          ) : null;
                        })
                        .filter((e) => e)}
                    </Select>

                    <Divider style={{}} />
                    <Select
                      mode='multiple'
                      allowClear
                      // placement='topLeft'
                      // bordered={false}
                      style={{ width: "15em", border: "1px solid rgb(6, 20, 31)", color: "rgb(6, 20, 31)", margin: "0.5em" }}
                      defaultValue={
                        filters !== undefined &&
                          bodyType !== undefined &&
                          bodyType !== ""
                          ? bodyType
                          : bodyType
                      }
                      placeholder='Body Type'
                      onChange={this.setCarBody}
                    >
                      <Option value=''>All Body Types</Option>
                      {this.props.fetchedStock
                        .sort((a, b) => a.bodyType.localeCompare(b.bodyType))
                        .map((stock, index) => {
                          if (duplicateCheck.includes(stock.bodyType))
                            return null;
                          duplicateCheck.push(stock.bodyType);
                          return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                            <Option
                              key={index}
                              value={stock.bodyType}
                              style={{
                                display: stock.bodyType === "" ? "none" : "",
                              }}
                            >
                              {stock.bodyType}
                            </Option>
                          ) : null;
                        })
                        .filter((e) => e)}
                    </Select>
                    <Divider />
                    <Select
                      mode='multiple'
                      allowClear
                      // placement='topLeft'
                      // bordered={false}
                      style={{ width: "15em", border: "1px solid rgb(6, 20, 31)", color: "rgb(6, 20, 31)", margin: "0.5em" }}
                      defaultValue={
                        filters !== undefined &&
                          transmission !== undefined &&
                          transmission !== ""
                          ? transmission
                          : transmission
                      }
                      placeholder='Transmission'
                      onChange={this.setCarTransmission}
                    >
                      <Option value=''>All Transmissions</Option>
                      {this.props.fetchedStock
                        .sort((a, b) =>
                          a.transmission.localeCompare(b.transmission)
                        )
                        .map((stock, index) => {
                          if (duplicateCheck.includes(stock.transmission))
                            return null;
                          duplicateCheck.push(stock.transmission);
                          return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                            <Option key={index} value={stock.transmission}>
                              {stock.transmission}
                            </Option>
                          ) : null;
                        })
                        .filter((e) => e)}
                    </Select>
                    <Divider />
                    <Select
                      mode='multiple'
                      allowClear
                      // placement='topLeft'
                      // bordered={false}
                      style={{ width: "15em", border: "1px solid rgb(6, 20, 31)", color: "rgb(6, 20, 31)", margin: "0.5em" }}
                      defaultValue={
                        filters !== undefined &&
                          vehicleDrive !== undefined &&
                          vehicleDrive !== ""
                          ? vehicleDrive
                          : vehicleDrive
                      }
                      placeholder='Drive Train'
                      onChange={this.setCarDriveTrain}
                    >
                      <Option value=''>All Drive Trains</Option>
                      {this.props.fetchedStock
                        .sort((a, b) =>
                          a.vehicleDrive.localeCompare(b.vehicleDrive)
                        )
                        .map((stock, index) => {
                          if (duplicateCheck.includes(stock.vehicleDrive))
                            return null;
                          duplicateCheck.push(stock.vehicleDrive);
                          return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                            <Option key={index} value={stock.vehicleDrive}>
                              {stock.vehicleDrive}
                            </Option>
                          ) : null;
                        })
                        .filter((e) => e)}
                    </Select>

                    <Divider />
                    <Select
                      mode='multiple'
                      allowClear
                      // placement='topLeft'
                      // bordered={false}
                      style={{ width: "15em", border: "1px solid rgb(6, 20, 31)", color: "rgb(6, 20, 31)", margin: "0.5em" }}
                      defaultValue={
                        filters !== undefined &&
                          branch !== undefined &&
                          branch !== ""
                          ? branch
                          : branch
                      }
                      placeholder='Branch'
                      onChange={this.setCarBranch}
                    >
                      <Option value=''>All Branches</Option>
                      {this.props.fetchedStock
                        .sort((a, b) => a.branch.localeCompare(b.branch))
                        .map((stock, index) => {
                          if (duplicateCheck.includes(stock.branch)) return null;
                          duplicateCheck.push(stock.branch);
                          return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                            <Option key={index} value={stock.branch}>
                              {stock.branch}
                            </Option>
                          ) : null;
                        })
                        .filter((e) => e)}
                    </Select>
                    <Divider />

                    <Select
                      mode='multiple'
                      allowClear
                      // placement='topLeft'
                      // bordered={false}
                      style={{ width: "15em", border: "1px solid rgb(6, 20, 31)", color: "rgb(6, 20, 31)", margin: "0.5em" }}
                      defaultValue={
                        filters !== undefined &&
                          fuelType !== undefined &&
                          fuelType !== ""
                          ? fuelType
                          : fuelType
                      }
                      placeholder='Fuel Type'
                      onChange={this.setCarFuelType}
                    >
                      <Option value=''>All Fuel Types</Option>
                      {this.props.fetchedStock
                        .sort((a, b) => a.fuelType.localeCompare(b.fuelType))
                        .map((stock, index) => {
                          if (duplicateCheck.includes(stock.fuelType))
                            return null;
                          duplicateCheck.push(stock.fuelType);
                          return stock.webVisible && stock.onSale && stock.megaDealers === "GWM" ? (
                            <Option key={index} value={stock.fuelType}>
                              {stock.fuelType}
                            </Option>
                          ) : null;
                        })
                        .filter((e) => e)}
                    </Select>
                    {/* <Divider />
                    <button
                      className='form-item'
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        marginLeft: "-0.2em",
                      }}
                    >
                      On Sale
                      <Checkbox
                        style={{ float: "right" }}
                        type='checkbox'
                        id='true'
                        onClick={this.setCarOnSale}
                      ></Checkbox>
                    </button> */}
                  </span>} trigger="click">
                  <Button style={{ width: "100%", color: "black", background: "transparent", height: "3.4em" }}>More Filters</Button>
                </Popover>
              </div>

              <div className='col-sm-2 col-md-2 col-lg-2 m-b-lg-20 ' style={{ marginTop: "-0.8em" }}>
                <button
                  type='button'
                  className='ht-btn ht-btn-default '
                  onClick={() => this.buttonSearchClicked()}
                  style={{ backgroundColor: "#E40401", borderRadius: "0.4em" }}
                >
                  <i className='fa fa-search'></i>Search Now
                </button>

              </div>
              {/*<button style={{ textAlign: "left" }} className='form-item'>
                Price
              </button>*/}{" "}


              <div className='col-sm-12 col-md-12 col-lg-12' style={{ marginTop: "0.5em", marginBottom: "2em" }}>
                <div className='col-sm-12 col-md-12 col-lg-2'></div>
                {/* <div className='col-sm-12 col-md-12 col-lg-4'>
                  <button
                    type='button'
                    className='ht-btn ht-btn-default m-t-lg-10'
                    onClick={() => this.viewAllStock()}
                    style={{ borderColor: "#24292f" }}
                  >
                    <i className='fa fa-refresh'></i>Reset Fields
                  </button>
                </div> */}

                {/* <div className='col-sm-12 col-md-12 col-lg-4'>
                  <button
                    type='button'
                    className='ht-btn ht-btn-default m-t-lg-10'
                    onClick={() => this.buttonSearchClicked()}
                    style={{ backgroundColor: "#E40401" }}
                  >
                    <i className='fa fa-search'></i>Search Now
                  </button>
                </div> */}
                {/*<Button onClick={this.viewAllStock}>Reset</Button>*/}
              </div>

            </div>

          </div>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='product product-grid product-grid-2 stock'>
              <div className='product-filter p-t-xs-20 p-l-xs-20'>
                <div className='m-b-xs-10 pull-left'>
                  <h3 style={{ padding: 5, marginBottom: -5 }}>

                    <span style={{}}>
                      {stock.length > 0 ? visibleCars : "0"} Vehicles on Prompotion
                    </span>{" "}
                  </h3>

                </div>
                <div className='pull-right'>
                  <div className='pull-left'>

                    <div className='select-wrapper'>

                      <label style={{ color: "black" }} >
                        <i className='fa fa-sort-alpha-asc' />
                        Show :{" "}
                      </label>
                      <div className='dropdown pull-left'>
                        <button
                          className='dropdown-toggle form-item w-80'
                          type='button'
                          id='dropdownMenu2'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='true'
                        >
                          {this.state.pageSize}
                        </button>
                        <ul
                          className='dropdown-menu'
                          aria-labelledby='dropdownMenu2'
                        >
                          <li>
                            <a onClick={() => this.setPageSize(6)}>6</a>
                          </li>
                          <li>
                            <a onClick={() => this.setPageSize(12)}>12</a>
                          </li>
                          <li>
                            <a onClick={() => this.setPageSize(24)}>24</a>
                          </li>
                          <li>
                            <a onClick={() => this.setPageSize(stock.length)}>
                              All
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='pull-right '
                  style={{
                    marginRight: window.isPhone ? "0em" : "15px",
                    marginTop: window.isPhone ? "0.5em" : "",
                  }}
                >
                  <div className='pull-left'>
                    <div className='select-wrapper' >
                      <a
                        onClick={() => this.setDisplayType("cards")}
                        className={
                          this.state.displayType === "cards" ? "active" : ""
                        }
                      >
                        <i className='fa fa-th' />
                      </a>
                      <a
                        onClick={() => this.setDisplayType("list")}
                        className={
                          this.state.displayType === "list" ? "active" : ""
                        }
                      >
                        <i className='fa fa-th-list' />
                      </a>
                      <label style={{ color: "black" }} >
                        <i className='fa fa-sort-amount-desc' />
                        Price :{" "}
                      </label>
                      <div className='dropdown pull-left'>
                        <button
                          style={{ width: window.isPhone ? "118px" : "135px" }}
                          className='dropdown-toggle form-item '
                          type='button'
                          id='dropdownMenu2'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='true'
                        >
                          {this.state.priceSort}
                        </button>
                        <ul
                          className='dropdown-menu'
                          aria-labelledby='dropdownMenu2'
                        >
                          <li>
                            <a onClick={this.onSortChangeFromLow}>
                              Low to High
                            </a>
                          </li>
                          <li>
                            <a onClick={this.onSortChangeFromHigh}>
                              High to Low
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='clearfix'></div>
              <div className='row'>{this.renderStock()}</div>
              <nav aria-label='Page navigation'>
                <ul
                  className='pagination ht-pagination'

                >
                  <li>
                    <a
                      aria-label='Previous'
                      style={
                        this.state.activePage === 1 || stock.length !== 0
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <span aria-hidden='true'>
                        <i
                          className='fa fa-chevron-left'
                          onClick={() => {
                            this.setActivePage(this.state.activePage - 1);
                          }}
                        />
                      </span>
                    </a>
                  </li>
                  {table.map((i) => {
                    if (table.length <= 1) return "";
                    else {
                      return (
                        <li
                          key={i}
                          className={
                            this.state.activePage === i ? "active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              this.setActivePage(i);
                            }}
                          >
                            {i}
                          </a>
                        </li>
                      );
                    }
                  })}
                  <li>
                    <a
                      aria-label='Next'
                      style={
                        this.state.activePage ===
                          Math.ceil(stock.length / this.state.pageSize) ||
                          stock.length !== 0
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <span aria-hidden='true'>
                        <i
                          className='fa fa-chevron-right'
                          onClick={() => {
                            this.setActivePage(this.state.activePage + 1);
                          }}
                        />
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section >
    );
  }
}
/**fetchedStock is the reducer state for the stock fetched from the API ,
 *  and homefilterActivated is to know if the user entred this page throught the home filter or not */
function mapStateToProps({ fetchedStock, homefilterActivated }) {
  return { fetchedStock, homefilterActivated };
}

export default connect(mapStateToProps, {
  fetchFiltredStock,
  turnOffHomefilter,
})(StockContainer);
