import React, { Component, useState } from "react";
import { Link } from "react-router-dom";

import kiaPromo from "./../videos/ca_kia_2243_HP-Sizzle-Q422_C_v02_H264-10MB.mp4";

import {
  Checkbox,
  Select,
  Divider,
  Slider,
  Switch,
  Collapse,
  Button,
  Popover,
  Row,
  Col,
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class KiaRange extends Component {
  render() {
    return (
      <section className="m-t-lg-30 m-t-xs-0" style={{ margin: "auto" }}>
        <div
          style={{
            background: "#e40401",
            height: "5em",
            width: "100vw",
            marginTop: window.isPhone ? "4em" : "9.5em",
            paddingLeft: window.isPhone ? "1em" : "7em",
          }}
        >
          <h3
            className="f-weight-600 f-30"
            style={{
              paddingTop: "0.6em",
              color: "white",
              maxWidth: "90em",
              margin: "auto",
              marginTop: "3em",
            }}
          >
            GWM Range
          </h3>
        </div>

        <div
          style={{
            background: "white",
            padding: 0,
          }}
        >
          <div
          // style={{ backgroundColor: "rgb(242, 244, 244)" }}
          >
            <Row
              justify="center"
              style={{
                margin: "auto",
              }}
            >
              <Col span={24}>
                <div className="video-overlay-1"></div>

                <img
                  src={`${process.env.PUBLIC_URL}/images/new-banner.jpg`}
                  style={{
                    width: "100vw",
                    height: window.isPhone ? "16em" : "20em",
                    objectFit: "cover",
                  }}
                />
              </Col>
              {/* <Col span={12}>
                </Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt='logo'
                  style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
                /> */}
            </Row>
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            marginTop: "0em",
            padding: window.isPhone ? "" : "3em",
            paddingTop: "1em",
          }}
        >
          <div
            className="col-sm-12 col-md-12 col-lg-12"
            style={{ marginBottom: "4em", marginTop: "2em" }}
          >
            <div className="product product-grid product-grid-2 stock">
              <div className="clearfix"></div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/steed-5-single-cab`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#d3d9df",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/steed-5-single-main.png`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/steed-5-single-cab`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          GWM Steed 5 Single Cab{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/steed-5-double-cab`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#d3d9df",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/steed-5-double-main.png`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/steed-5-double-cab`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          GWM Steed 5 Double Cab{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link
                    className="product-img"
                    to={`/p-series-commercial-single-cab`}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#d3d9df",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-commercial-single-cab-main.png`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/p-series-commercial-single-cab`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          GWM P-Series Commercial Single Cab{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link
                    className="product-img"
                    to={`/p-series-commercial-double-cab`}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#d3d9df",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-commercial-double-cab-main.png`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/p-series-commercial-double-cab`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          GWM P-Series Commercial Double Cab{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link
                    className="product-img"
                    to={`/p-series-passenger-double-cab`}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#d3d9df",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-passenger-double-cab-main.png`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/p-series-passenger-double-cab`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          GWM P-Series Passenger Double Cab{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/p-series-ltd-double`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#d3d9df",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/p-series-ltd-double-cab-main.png`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/p-series-ltd-double`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          GWM P-Series LTD Double Cab{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default KiaRange;
