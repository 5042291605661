import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Basket from "./Basket";
import { turnOffHomefilter, turnOnHomefilter } from "../redux/actions";
import { connect } from "react-redux";

import {
  Menu,
  Dropdown,
  message,
  Badge,
  Col,
  Row,
  Button,
  Modal,
  Collapse,
  Form,
  Input,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { slide as Menu1 } from "react-burger-menu";
import $ from "jquery";
import styled from "styled-components";
import CurrencyFormat from "react-currency-format";

import InputRange from "react-input-range";
const { Option } = Select;
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Group, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledBadge = styled(Badge)`
  border-radius: 20em !important;
`;
const StyledModal = styled(Modal)`
  .ant-modal-header {
    display: none;
    border-radius: 0.5em !important;
    text-align: center !important;
    border-bottom: 0px solid #f0f0f0 !important;
  }
  .ant-modal-content {
    height: 43em !important;
    width: 60vw;
    margin: auto !important;
    background-color: #06141f !important;
  }
`;
const StyledModalTestDrive = styled(Modal)`
  .ant-modal-header {
    display: none;
    border-radius: 0.5em !important;
    text-align: center !important;
    border-bottom: 0px solid #f0f0f0 !important;
  }
  .ant-modal-content {
    height: 43em !important;
    width: 60vw;
    margin: auto !important;
    background-color: white !important;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: transparent !important;

    box-shadow: none !important;
  }
  :active {
    border-color: transparent !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: transparent !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: transparent !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
const StyledSelectTestDrive = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid #06141f !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid #06141f !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: transparent !important;

    box-shadow: none !important;
  }
  :active {
    border-color: transparent !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: transparent !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: transparent !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
const StyledInput = styled(Input)`


  //padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: transparent!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid white !important;
  border-radius: 0px;
  color: white !important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  .ant-input-group-addon .ant-select {
    margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px !important;
  color: white !important;
}
.ant-select-arrow {

  color: white !important;

}
.ant-input-group-addon {
  padding: 0px !important;
  color: white !important;
  background-color: rgb(228, 4, 1) !important;
  border: 0px solid grey !important;
 
}
 input.ant-input {
  padding: 0;
  color: white !important;
  border: none;
  background: transparent !important;
  outline: none;
}
.ant-input-affix-wrapper {

  height: 43px !important;
  border: none !important;}
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: black !important;

    box-shadow:none !important;
}
  :active{
    border-color: black !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: black !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledInputTestDrive = styled(Input)`

//padding-left: 1.2em !important;
height: 45px !important;

  box-shadow:none !important;
background-color: transparent!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid #06141f !important;
border-radius: 0px;
color: #06141f!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input-group-addon .ant-select {
  margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
padding-right: 20px !important;
color: #06141f !important;
}
.ant-select-arrow {

color: #06141f !important;

}
.ant-input-group-addon {
padding: 0px !important;
color: #06141f !important;
background-color: rgb(228, 4, 1) !important;
border: 0px solid grey !important;

}
input.ant-input {
padding: 0;
color: #06141f !important;
border: none;
background: transparent !important;
outline: none;
}
.ant-input-affix-wrapper {

height: 43px !important;
border: none !important;}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: black !important;

  box-shadow:none !important;
}
:active{
  border-color: black !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: black !important;
  
   box-shadow:none !important;
 
}
}
`;
//useEffect(() => {
//  window.scrollTo(0, 0);
//}, []);

class FinanceBox extends React.Component {
  state = {
    amountValue: 30000,
    monthsValue: 12,
    depositValue: 0,
    balloonValue: 0,
    interestValue: 7,
  };
  handleAmountChange = (value) => {
    this.setState({ amountValue: value });
  };
  handleDepositChange = (value) => {
    this.setState({ depositValue: value });
  };
  handleMonthsChange = (value) => {
    this.setState({ monthsValue: value });
  };
  handleBalloonChange = (value) => {
    this.setState({ balloonValue: value });
  };
  handleInterestChange = (value) => {
    this.setState({ interestValue: value });
  };

  render() {
    let {
      amountValue,
      depositValue,
      monthsValue,
      balloonValue,
      interestValue,
    } = this.state;

    return (
      <Row style={{ marginBottom: "2em" }}>
        <Col span={14}>
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}>Vehicle Amount</span>
            <span className="color-red" style={{ float: "right" }}>
              R {amountValue}
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={600000}
            minValue={10000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Deposit Amount</span>{" "}
            <span className="color-red" style={{ float: "right" }}>
              R {depositValue}
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={amountValue - 30000}
            minValue={0}
            value={depositValue}
            onChange={this.handleDepositChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Over</span>
            <span className="color-red" style={{ float: "right" }}>
              {monthsValue} Month{monthsValue > 1 && "s"}
            </span>
          </h5>
          <InputRange
            step={12}
            maxValue={96}
            minValue={12}
            value={monthsValue}
            onChange={this.handleMonthsChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Interest Rate</span>
            <span className="color-red" style={{ float: "right" }}>
              {interestValue}%
            </span>
          </h5>
          <InputRange
            step={0.25}
            maxValue={17}
            minValue={7}
            value={interestValue}
            onChange={this.handleInterestChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Balloon Payment</span>
            <span className="color-red" style={{ float: "right" }}>
              {balloonValue}%
            </span>
          </h5>
          <InputRange
            step={1}
            maxValue={8}
            minValue={0}
            value={balloonValue}
            onChange={this.handleBalloonChange}
          />
        </Col>{" "}
        <Col span={10}>
          <Display
            months={monthsValue}
            amount={amountValue}
            deposit={depositValue}
            balloon={balloonValue}
            interest={interestValue}
          />
        </Col>
      </Row>
    );
  }
}
class Display extends React.Component {
  state = {
    APR: 0.05,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateAPR();
    }
  }

  calculateAPR = () => {
    let { amount } = this.props;

    if (1000 < amount && amount < 5000) {
      this.setState({ APR: 0.05 });
    }
    if (5000 < amount && amount < 10000) {
      this.setState({ APR: 0.1 });
    }
    if (10000 < amount && amount < 15000) {
      this.setState({ APR: 0.15 });
    }
    if (15000 < amount && amount < 20000) {
      this.setState({ APR: 0.2 });
    }
  };

  calculateMonthlyRepayment = () => {
    let { amount, months, deposit, interest, balloon } = this.props;
    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;

    //let { amount, months, deposit, interest } = this.props;
    //let amountInterestDec = interest / 100;
    //let amountInterestMonth = amountInterestDec / 12;
    //let amountInterest = amountInterestMonth;
    //let montsCalc = Math.pow(1 + amountInterest, months);
    //let monthlyRepayment1 = amountInterest * montsCalc;
    //let monthlyRepayment2 = montsCalc - 1;
    //let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    //let amountDeDeposit = amount - deposit;
    //let monthlyRepaymentTotal = amountDeDeposit * monthlyRepaymentIntersest;
    //let monthlyRepayment = monthlyRepaymentTotal + 94;

    return (
      <h4 className="finance-box-p color-red">
        R{Math.round(monthlyRepayment < 0 ? "0" : monthlyRepayment)}
      </h4>
    );
  };
  calculateInterest = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentInterest =
      monthlyRepayment * months - (amount - deposit);
    return (
      <span>
        <h4 className="finance-box-p color-red">
          R
          {Math.round(
            monthlyRepaymentInterest < 0 ? "0" : monthlyRepaymentInterest
          )}
        </h4>

        <p style={{ marginBottom: "0em" }}>Interest Amount</p>
      </span>
    );
  };
  calculateTotal = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal * months;
    let monthlyRepaymentall = monthlyRepayment;
    return (
      <span>
        <h4 className="finance-box-p color-red">
          R{Math.round(monthlyRepaymentall < 0 ? "0" : monthlyRepaymentall)}
        </h4>

        <p style={{ marginBottom: "0em" }}>Total Repayment</p>
      </span>
    );
  };
  calculateAmount = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    return (
      <span>
        <h4 className="finance-box-p color-red">
          R{Math.round(amount - deposit < 0 ? "0" : amount - deposit)}
        </h4>

        <p style={{ marginBottom: "0em" }}>Amount To Finance</p>
      </span>
    );
  };
  percentageAPR = () => {
    return <h4 className="finance-box-p color-red">{this.state.APR * 100}%</h4>;
  };

  render() {
    return (
      <div className="finance-box-flex">
        {/*<DisplayChild func={this.percentageAPR()} text='Interest Rate' />*/}
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          intr={this.calculateInterest()}
          total={this.calculateTotal()}
          fin={this.calculateAmount()}
          text="Monthly Repayment"
        />
      </div>
    );
  }
}

const DisplayChild = ({ func, text, intr, total, fin }) => {
  return (
    <span>
      {func} <p style={{ marginBottom: "0em" }}>{text}</p>
      <br />
      {fin}
      <br />
      {intr}
      <br />
      {total}
    </span>
  );
};
$(function () {
  var logo = $(".lrg-logo");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      if (!logo.hasClass("sml-logo")) {
        //logo.hide();
        logo.removeClass("lrg-logo").addClass("sml-logo").fadeIn("slow");
      }
    } else {
      if (!logo.hasClass("lrg-logo")) {
        //logo.hide();
        logo.removeClass("sml-logo").addClass("lrg-logo").fadeIn("slow");
      }
    }
  });
});
$(function () {
  var logo = $(".bm-burger-button");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      if (!logo.hasClass("bm-burger-button-sml")) {
        //logo.hide();
        logo
          .removeClass("bm-burger-button")
          .addClass("bm-burger-button-sml")
          .fadeIn("slow");
      }
    } else {
      if (!logo.hasClass("bm-burger-button")) {
        //logo.hide();
        logo
          .removeClass("bm-burger-button-sml")
          .addClass("bm-burger-button")
          .fadeIn("slow");
      }
    }
  });
});
$(function () {
  var header = $(".top-header-lrg");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      if (!header.hasClass("top-headers")) {
        //header.hide();
        header
          .removeClass("top-header-lrg")
          .addClass("top-headers")
          .fadeIn("slow");
      }
    } else {
      if (!header.hasClass("top-header-lrg")) {
        //header.hide();
        header
          .removeClass("top-headers")
          .addClass("top-header-lrg")
          .fadeIn("slow");
      }
    }
  });
});
const onClick = ({ key }) => {
  message.info(` ${key} Website Coming Soon!`);
};
const { SubMenu } = Menu;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      menuOpen: false,
      visibleService: false,
      visibleTestDrive: false,
    };
  }
  showService = () => {
    this.setState({
      visibleService: true,
    });
  };
  handleOkService = (e) => {
    console.log(e);
    this.setState({
      visibleService: false,
    });
  };

  handleCancelService = (e) => {
    console.log(e);
    this.setState({
      visibleService: false,
    });
  };
  showTestDrive = () => {
    this.setState({
      visibleTestDrive: true,
    });
  };
  handleOkTestDrive = (e) => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };

  handleCancelTestDrive = (e) => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    //window.location.assign("./");
  };
  scrollOnclick() {
    //window.location.reload();
  }
  scrollOnclickHome() {
    window.location.reload();
  }
  render() {
    return (
      <div style={{}}>
        {/*desktop-view*/}
        <header
          id="wrap-header"
          className="desktop-view color-inher"
          style={{ backgroundColor: "transparent" }}
        >
          <div
            className="top-header top-header-lrg"
            style={{
              backgroundColor: "transparent",
              margin: "auto",
              boxShadow: "inset -10px 80px 60px -40px white",
            }}
          >
            <div className="container-custom">
              <div
                className="row"
                style={{
                  margin: "auto",
                  maxWidth: "100vw",
                  textAlign: "center",
                }}
              >
                <div className="col-md-2 col-lg-2">
                  <span className="logo" onClick={this.scrollOnclickHome}>
                    <Link to={"/"} className="dropdown-toggle">
                      <img
                        className="lrg-logo"
                        style={{
                          padding: 0,
                          marginTop: "-1em",
                          width: "7em",
                        }}
                        src={`${process.env.PUBLIC_URL}/images/gwm-menu-logo.png`}
                        alt="logo"
                      />
                    </Link>
                  </span>
                </div>
                <div
                  className="col-sm-10 col-md-10 col-lg-10"
                  style={{ marginTop: "1em" }}
                >
                  <ul style={{ display: "inline-flex" }}>
                    {/* <li className='dropdown' onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName='active'
                        className='lrg-logo'
                        to={"/about-kia"}

                      >
                        <span > About Kia</span>
                      </NavLink>
                    </li> */}
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink className="lrg-logo" to={"/gwm-range"}>
                        <span> Explore Range</span>
                      </NavLink>
                    </li>
                    {/* <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink className="lrg-logo" to={"/stock"}>
                        <span> More Vehicles</span>
                      </NavLink>
                    </li> */}
                    {/* <li className='dropdown' onClick={this.scrollOnclick} style={{ color: "white", paddingTop: "3px", fontSize: "18px" }}>
                      <span className='lrg-logo'>
                        <Dropdown overlay={<Menu >
                          <Menu.Item key='Book a Service' onClick={this.showService}>Book a Service</Menu.Item>
                          <Menu.Item key='Book a Test Drive' onClick={this.showTestDrive}>Book a Test Drive</Menu.Item>
                        </Menu>} >
                          <span>
                            Bookings <DownOutlined />
                          </span>
                        </Dropdown>
                      </span>
                    </li> */}
                    {/* <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName="active"
                        to={"/promotions"}
                        className="lrg-logo"
                      >
                        <span> Promotions</span>
                      </NavLink>
                    </li> */}
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink className="lrg-logo" to={"./Specials"}>
                        <span> Specials</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink className="lrg-logo" to={"./Accessories"}>
                        <span> Accessories</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink className="lrg-logo" to={"./Service"}>
                        <span> Service</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink className="lrg-logo" to={"./Parts"}>
                        <span> Parts</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName="active"
                        to={"/blogs"}
                        className="lrg-logo"
                      >
                        <span> Blogs</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName="active"
                        to={"/contactUs"}
                        className="lrg-logo"
                        style={{ paddingRight: 0 }}
                      >
                        <span> Contact Us</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
                {/* <div className='col-md-2 col-lg-2' style={{ marginTop: "1.3em" }}>
                  <span className='logo' onClick={this.scrollOnclickHome}>
                    <Link to={"/"} className='dropdown-toggle'>
                      <img
                        className='lrg-logo'
                        style={{ padding: 0 }}
                        src={`${process.env.PUBLIC_URL}/images/haval-white.png`}
                        alt='logo'
                      />
                    </Link>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
          {/*<div className="search-box">
          <i className="fa fa-search" />
          <form>
            <input
              type="text"
              name="search-txt"
              placeholder="Search..."
              className="search-txt form-item"
            />
            <button type="submit" name="submit" className="search-btn btn-1">
              <i className="fa fa-search" />
            </button>
          </form>
        </div>*/}
          {/* <span className='btn fav' href='#'>
            <li className='cart-icon'>
              <Link to={"/wishlist"} >
                <i
                  className={
                    this.props.wishlistproducts.length > 0
                      ? "fa fa-heart"
                      : "fa fa-heart-o"
                  }
                />{" "}
                Favourite

              </Link>
            </li>
          </span> */}
          {/* <span className='btn get-in-touch' href='#'>
            <li className='cart-icon' onClick={this.scrollOnclick}>
              <Link to={"/contactUs"} >
                <i className={"fa fa-phone"} /> Get in Touch
              </Link>
            </li>
          </span> */}
        </header>
        {/*mobile-view*/}
        <header id="wrap-header" className="mobile-view">
          <div className="top-header top-header-lrg">
            <div className="row">
              <div
                className="col-md-2 col-lg-2"
                style={{ textAlign: "center" }}
              >
                <span className="logo" onClick={this.scrollOnclickHome}>
                  <Link to={"/"} className="dropdown-toggle">
                    <img
                      className="lrg-logo"
                      src={`${process.env.PUBLIC_URL}/images/gwm-menu-logo.png`}
                      alt="logo"
                    />
                  </Link>
                </span>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10">
                <Menu1
                  right
                  isOpen={this.state.menuOpen}
                  onStateChange={(state) => this.handleStateChange(state)}
                >
                  <div className="banner-item-menu ">
                    <div className="row text-align-lg-center ">
                      <div
                        className="col-md-12 col-lg-12"
                        style={{ marginTop: "4em" }}
                      >
                        <span
                          className="logo-menu"
                          onClick={() => this.closeMenu()}
                        >
                          <Link to={"/"} className="dropdown-toggle">
                            <img
                              className="logo-menu"
                              src={`${process.env.PUBLIC_URL}/images/gwm-menu-logo.png`}
                              alt="logo"
                            />
                          </Link>
                        </span>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row text-align-lg-center color-white">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          onClick={() => this.closeMenu()}
                          to={"/gwm-range"}
                        >
                          Explore Range
                        </Link>
                      </div>
                    </div>
                    {/* <br /> */}
                    {/* <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          to={"/stock"}
                          onClick={() => this.closeMenu()}
                        >
                          More Vehicles
                        </Link>
                      </div>
                    </div> */}
                    {/* <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          to={"/promotions"}
                          onClick={() => this.closeMenu()}
                        >
                          Promotions
                        </Link>
                      </div>
                    </div> */}
                    <br />
                    <div className="row text-align-lg-center color-white">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          onClick={() => this.closeMenu()}
                          to={"/Specials"}
                        >
                          Specials
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center color-white">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          onClick={() => this.closeMenu()}
                          to={"/Accessories"}
                        >
                          Accessories
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center color-white">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          onClick={() => this.closeMenu()}
                          to={"/Service"}
                        >
                          Service
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center color-white">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          onClick={() => this.closeMenu()}
                          to={"/Parts"}
                        >
                          Parts
                        </Link>
                      </div>
                    </div>
                    <br />
                    {/*<div className="row">
                      <div className="col-md-12 col-lg-12">
                      <li className="dropdown" onClick={this.scrollOnclick}>
                          <Dropdown overlay={menu}>
                            <a
                              className="icon-1"
                              onClick={(e) => e.preventDefault()}
                            >
                              <span>
                                Branches <DownOutlined />
                              </span>
                            </a>
                          </Dropdown>
                        </li>
                      </div>
                    </div>*/}
                    {/*<div className='row text-align-lg-center'>
                      <div className='col-md-12 col-lg-12'>
                        <Link
                          className='color-white f-20'
                          to={"/sellacar"}
                          onClick={() => this.closeMenu()}
                        >
                          Financing
                        </Link>
                      </div>
                    </div>
                    <br />*/}
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          to={"/blogs"}
                          onClick={() => this.closeMenu()}
                        >
                          Blogs
                        </Link>
                      </div>
                    </div>
                    {/* <br />
                    <div className='row text-align-lg-center'>
                      <div className='col-md-12 col-lg-12'>
                        <span
                          className='color-white f-20'
                          // to={"/blogs"}
                          onClick={this.showTestDrive}
                        >
                          Book a Test Drive
                        </span>
                      </div>
                    </div> */}
                    <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-white f-20"
                          to={"/contactUs"}
                          onClick={() => this.closeMenu()}
                        >
                          Get in Touch
                        </Link>
                      </div>
                    </div>
                    <br />{" "}
                  </div>
                </Menu1>{" "}
                {this.props.wishlistproducts.length > 0 ? (
                  <Link
                    style={{
                      top: 70,
                      right: 0,
                      position: "absolute",
                      marginTop: "0.7em",
                      backgroundColor: "#05141f",
                      padding: "0.7em 1em 0.7em 0.7em",
                      color: "white",
                      fontWeight: 500,
                      borderRadius: "0.7em 1em 0.7em 0.7em",
                    }}
                    className="color-white f-20"
                    to={"/wishlist"}
                  >
                    <i
                      className={
                        this.props.wishlistproducts.length > 0
                          ? "fa fa-heart-o"
                          : "fa fa-heart-o"
                      }
                    />{" "}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </header>{" "}
        <StyledModal
          title="Basic Modal"
          visible={this.state.visibleService}
          onOk={this.handleOkService}
          onCancel={this.handleCancelService}
        >
          <h2
            className="f-weight-600"
            style={{
              fontSize: "40px",
              textAlign: "left",
              whiteSpace: "nowrap",
              color: "white",
              marginBottom: "1em",
            }}
          >
            <br />- BOOK A SERVICE
          </h2>
          <Form
            onFinish={this.handleSubmit}
            initialValues={{
              remember: true,
            }}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInput placeholder="Name" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="surname"
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInput placeholder="Surname" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="cellPhone"
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInput placeholder="Number" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="email"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInput placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="make"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInput placeholder="Vehicle Make" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="model"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInput placeholder="Vehicle Model" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="dealership"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledSelect
                  bordered={false}
                  style={{
                    borderRadius: "0em",
                    height: 50,
                    color: "white",
                  }}
                  placeholder="Dealership"
                  //onChange={this.setCarEngineCapacity}
                >
                  <Option key={1} value="mahikeng">
                    Kia Mahikeng
                  </Option>
                </StyledSelect>
              </Form.Item>
            </div>

            <div className="col-lg-12 m-t-lg-12 text-center">
              <Form.Item>
                <Button
                  style={{
                    fontFamily: "Arial",
                    border: "1px solid white",
                    fontSize: "15px",
                    fontWeight: 600,
                    borderRadius: "0em",
                    backgroundColor: "white",
                    color: "black",
                    height: "55px",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    textShadow: "none",
                    margin: "auto",
                    width: "100%",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  SEND ENQUIRY
                </Button>{" "}
              </Form.Item>{" "}
              <p
                style={{
                  color: "white",
                  //marginBottom: "2.5em",
                  marginTop: "0em",
                  fontSize: "13px",
                  lineHeight: "1.5em",
                }}
              >
                By submitting this form, you agree to our{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link to="/terms">Terms of Use</Link>
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    color: "white",
                  }}
                >
                  <Link to="/privacy-policy">Privacy Statement</Link>
                </span>
                .
              </p>{" "}
            </div>
          </Form>
        </StyledModal>
        <StyledModalTestDrive
          title="Basic Modal"
          visible={this.state.visibleTestDrive}
          onOk={this.handleOkTestDrive}
          onCancel={this.handleCancelTestDrive}
        >
          <h2
            className="f-weight-600"
            style={{
              fontSize: "40px",
              textAlign: "left",
              whiteSpace: "nowrap",
              color: "black",
              marginBottom: "1em",
            }}
          >
            <br />- BOOK A TEST DRIVE
          </h2>
          <Form
            onFinish={this.handleSubmit}
            initialValues={{
              remember: true,
            }}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder="Name" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="surname"
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder="Surname" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="cellPhone"
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder="Number" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="email"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="make"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder="Vehicle Make" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="model"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder="Vehicle Model" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="dealership"
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledSelectTestDrive
                  bordered={false}
                  style={{
                    borderRadius: "0em",
                    height: 50,
                    color: "#06141f",
                  }}
                  placeholder="Dealership"
                  //onChange={this.setCarEngineCapacity}
                >
                  <Option key={1} value="mahikeng">
                    Kia Mahikeng
                  </Option>
                </StyledSelectTestDrive>
              </Form.Item>
            </div>

            <div className="col-lg-12 m-t-lg-12 text-center">
              <Form.Item>
                <Button
                  style={{
                    fontFamily: "Arial",
                    border: "1px solid #06141f",
                    fontSize: "15px",
                    fontWeight: 600,
                    borderRadius: "0em",
                    backgroundColor: "#06141f",
                    color: "white",
                    height: "55px",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    textShadow: "none",
                    margin: "auto",
                    width: "100%",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  SEND ENQUIRY
                </Button>{" "}
              </Form.Item>{" "}
              <p
                style={{
                  color: "#06141f",
                  //marginBottom: "2.5em",
                  marginTop: "0em",
                  fontSize: "13px",
                  lineHeight: "1.5em",
                }}
              >
                By submitting this form, you agree to our{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link style={{ color: "#06141f" }} to="/terms">
                    Terms of Use
                  </Link>
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    color: "#06141f",
                  }}
                >
                  <Link style={{ color: "#06141f" }} to="/privacy-policy">
                    Privacy Statement
                  </Link>
                </span>
                .
              </p>{" "}
            </div>
          </Form>
        </StyledModalTestDrive>
      </div>
    );
  }
}
function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  turnOffHomefilter,
  turnOnHomefilter,
})(Header);
