import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Basket from "./Basket";
import { turnOffHomefilter, turnOnHomefilter } from "../redux/actions";
import { connect } from "react-redux";
import {
  Menu,
  Dropdown,
  message,
  Badge,
  Col,
  Row,
  Button,
  Modal,
  Collapse,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { slide as Menu1 } from "react-burger-menu";
import $ from "jquery";
import styled from "styled-components";
import CurrencyFormat from "react-currency-format";

import InputRange from "react-input-range";
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Group, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledBadge = styled(Badge)`
  border-radius: 20em !important;
`;
const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 0.5em !important;
    text-align: center !important;
    border-bottom: 0px solid #f0f0f0 !important;
  }
  .ant-modal-content {
    padding: 0.5em !important;
    border-radius: 0.5em !important;
  }
`;
//useEffect(() => {
//  window.scrollTo(0, 0);
//}, []);
class FinanceBox extends React.Component {
  state = {
    amountValue: 30000,
    monthsValue: 12,
    depositValue: 0,
    balloonValue: 0,
    interestValue: 7,
  };
  handleAmountChange = value => {
    this.setState({ amountValue: value });
  };
  handleDepositChange = value => {
    this.setState({ depositValue: value });
  };
  handleMonthsChange = value => {
    this.setState({ monthsValue: value });
  };
  handleBalloonChange = value => {
    this.setState({ balloonValue: value });
  };
  handleInterestChange = value => {
    this.setState({ interestValue: value });
  };

  render() {
    let {
      amountValue,
      depositValue,
      monthsValue,
      balloonValue,
      interestValue,
    } = this.state;

    return (
      <Row style={{ marginBottom: "2em" }}>
        <Col span={14}>
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}>Vehicle Amount</span>
            <span className="color-red" style={{ float: "right" }}>
              R {amountValue}
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={600000}
            minValue={10000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Deposit Amount</span>{" "}
            <span className="color-red" style={{ float: "right" }}>
              R {depositValue}
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={amountValue - 30000}
            minValue={0}
            value={depositValue}
            onChange={this.handleDepositChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Over</span>
            <span className="color-red" style={{ float: "right" }}>
              {monthsValue} Month{monthsValue > 1 && "s"}
            </span>
          </h5>
          <InputRange
            step={12}
            maxValue={96}
            minValue={12}
            value={monthsValue}
            onChange={this.handleMonthsChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Interest Rate</span>
            <span className="color-red" style={{ float: "right" }}>
              {interestValue}%
            </span>
          </h5>
          <InputRange
            step={0.25}
            maxValue={17}
            minValue={7}
            value={interestValue}
            onChange={this.handleInterestChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Balloon Payment</span>
            <span className="color-red" style={{ float: "right" }}>
              {balloonValue}%
            </span>
          </h5>
          <InputRange
            step={1}
            maxValue={8}
            minValue={0}
            value={balloonValue}
            onChange={this.handleBalloonChange}
          />
        </Col>{" "}
        <Col span={10}>
          <Display
            months={monthsValue}
            amount={amountValue}
            deposit={depositValue}
            balloon={balloonValue}
            interest={interestValue}
          />
        </Col>
      </Row>
    );
  }
}
class Display extends React.Component {
  state = {
    APR: 0.05,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateAPR();
    }
  }

  calculateAPR = () => {
    let { amount } = this.props;

    if (1000 < amount && amount < 5000) {
      this.setState({ APR: 0.05 });
    }
    if (5000 < amount && amount < 10000) {
      this.setState({ APR: 0.1 });
    }
    if (10000 < amount && amount < 15000) {
      this.setState({ APR: 0.15 });
    }
    if (15000 < amount && amount < 20000) {
      this.setState({ APR: 0.2 });
    }
  };

  calculateMonthlyRepayment = () => {
    let { amount, months, deposit, interest, balloon } = this.props;
    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;

    //let { amount, months, deposit, interest } = this.props;
    //let amountInterestDec = interest / 100;
    //let amountInterestMonth = amountInterestDec / 12;
    //let amountInterest = amountInterestMonth;
    //let montsCalc = Math.pow(1 + amountInterest, months);
    //let monthlyRepayment1 = amountInterest * montsCalc;
    //let monthlyRepayment2 = montsCalc - 1;
    //let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    //let amountDeDeposit = amount - deposit;
    //let monthlyRepaymentTotal = amountDeDeposit * monthlyRepaymentIntersest;
    //let monthlyRepayment = monthlyRepaymentTotal + 94;

    return (
      <h4 className="finance-box-p color-red">
        R{Math.round(monthlyRepayment < 0 ? "0" : monthlyRepayment)}
      </h4>
    );
  };
  calculateInterest = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentInterest =
      monthlyRepayment * months - (amount - deposit);
    return (
      <span>
        <h4 className="finance-box-p color-red">
          R
          {Math.round(
            monthlyRepaymentInterest < 0 ? "0" : monthlyRepaymentInterest
          )}
        </h4>

        <p style={{ marginBottom: "0em" }}>Interest Amount</p>
      </span>
    );
  };
  calculateTotal = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal * months;
    let monthlyRepaymentall = monthlyRepayment;
    return (
      <span>
        <h4 className="finance-box-p color-red">
          R{Math.round(monthlyRepaymentall < 0 ? "0" : monthlyRepaymentall)}
        </h4>

        <p style={{ marginBottom: "0em" }}>Total Repayment</p>
      </span>
    );
  };
  calculateAmount = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    return (
      <span>
        <h4 className="finance-box-p color-red">
          R{Math.round(amount - deposit < 0 ? "0" : amount - deposit)}
        </h4>

        <p style={{ marginBottom: "0em" }}>Amount To Finance</p>
      </span>
    );
  };
  percentageAPR = () => {
    return <h4 className="finance-box-p color-red">{this.state.APR * 100}%</h4>;
  };

  render() {
    return (
      <div className="finance-box-flex">
        {/*<DisplayChild func={this.percentageAPR()} text='Interest Rate' />*/}
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          intr={this.calculateInterest()}
          total={this.calculateTotal()}
          fin={this.calculateAmount()}
          text="Monthly Repayment"
        />
      </div>
    );
  }
}

const DisplayChild = ({ func, text, intr, total, fin }) => {
  return (
    <span>
      {func} <p style={{ marginBottom: "0em" }}>{text}</p>
      <br />
      {fin}
      <br />
      {intr}
      <br />
      {total}
    </span>
  );
};
$(function () {
  var logo = $(".lrg-logo");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      if (!logo.hasClass("sml-logo")) {
        //logo.hide();
        logo.removeClass("lrg-logo").addClass("sml-logo").fadeIn("slow");
      }
    } else {
      if (!logo.hasClass("lrg-logo")) {
        //logo.hide();
        logo.removeClass("sml-logo").addClass("lrg-logo").fadeIn("slow");
      }
    }
  });
});
$(function () {
  var logo = $(".bm-burger-button");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      if (!logo.hasClass("bm-burger-button-sml")) {
        //logo.hide();
        logo
          .removeClass("bm-burger-button")
          .addClass("bm-burger-button-sml")
          .fadeIn("slow");
      }
    } else {
      if (!logo.hasClass("bm-burger-button")) {
        //logo.hide();
        logo
          .removeClass("bm-burger-button-sml")
          .addClass("bm-burger-button")
          .fadeIn("slow");
      }
    }
  });
});
$(function () {
  var header = $(".top-header-lrg");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
      if (!header.hasClass("top-headers")) {
        //header.hide();
        header
          .removeClass("top-header-lrg")
          .addClass("top-headers")
          .fadeIn("slow");
      }
    } else {
      if (!header.hasClass("top-header-lrg")) {
        //header.hide();
        header
          .removeClass("top-headers")
          .addClass("top-header-lrg")
          .fadeIn("slow");
      }
    }
  });
});
const onClick = ({ key }) => {
  message.info(` ${key} Website Coming Soon!`);
};
const { SubMenu } = Menu;
const menu = (
  <Menu onClick={onClick}>
    <SubMenu key="1" title="Pre-Owned">
      <Menu.Item key="GWM Klerksdorp">Klerksdorp</Menu.Item>
      <Menu.Item key="GWM Potchefstroom">Potchefstroom</Menu.Item>
      <Menu.Item key="GWM Lichtenburg">Lichtenburg</Menu.Item>
    </SubMenu>
    <SubMenu key="2" title="Kia">
      <Menu.Item key="Kia Mahikeng">Mahikeng</Menu.Item>
    </SubMenu>
    <SubMenu key="3" title="Mahindra">
      <Menu.Item key="Mahindra Mahikeng">Mahikeng</Menu.Item>
    </SubMenu>
    <SubMenu key="4" title="Chery">
      <Menu.Item key="Chery Klerksdorp">Klerksdorp</Menu.Item>
    </SubMenu>
    <SubMenu key="5" title="GWM">
      <Menu.Item key="GWM Klerksdorp">Klerksdorp</Menu.Item>
      <Menu.Item key="GWM Potchefstroom">Potchefstroom</Menu.Item>
      <Menu.Item key="GWM Mahikeng">Mahikeng</Menu.Item>
      <Menu.Item key="GWM Lichtenburg">Lichtenburg</Menu.Item>
    </SubMenu>
    <SubMenu key="6" title="Haval">
      <Menu.Item key="Haval Klerksdorp">Klerksdorp</Menu.Item>
      <Menu.Item key="Haval Potchefstroom">Potchefstroom</Menu.Item>
      <Menu.Item key="Haval Mahikeng">Mahikeng</Menu.Item>
      <Menu.Item key="Haval Lichtenburg">Lichtenburg</Menu.Item>
    </SubMenu>
  </Menu>
);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      menuOpen: false,
      visibleService: false,
      visibleTestDrive: false,
    };
  }
  showService = () => {
    this.setState({
      visibleService: true,
    });
  };
  handleOkService = e => {
    console.log(e);
    this.setState({
      visibleService: false,
    });
  };

  handleCancelService = e => {
    console.log(e);
    this.setState({
      visibleService: false,
    });
  };
  showTestDrive = () => {
    this.setState({
      visibleTestDrive: true,
    });
  };
  handleOkTestDrive = e => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };

  handleCancelTestDrive = e => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }
  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    //window.location.assign("./");
  };
  scrollOnclick() {
    //window.location.reload();
  }
  scrollOnclickHome() {
    window.location.reload();
  }
  render() {
    return (
      <div>
        {/*desktop-view*/}
        <header
          id="wrap-header"
          className="desktop-view color-inher"
          style={{ backgroundColor: "transparent" }}
        >
          <div style={{ backgroundColor: "#06141f", height: "4em" }}></div>
          <div
            className="top-header top-header-lrg"
            style={{
              backgroundColor: "white",
              paddingLeft: "6em",
              paddingRight: "6em",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <div className="container-custom">
              <div className="row">
                <div className="col-sm-5 col-md-5 col-lg-5">
                  <ul className="pull-left">
                    {/*<li
                     onClick={this.scrollOnclick}
                    className="dropdown"
                  >
                    <Link to={"/"} className="dropdown-toggle">
                      HOME
                    </Link>
                  </li>*/}
                    {/* <li className='dropdown' onClick={this.scrollOnclick}>
                      <NavLink

                        onClick={() => this.props.turnOffHomefilter()}
                        to={"/stock"}

                      >
                        <span style={{ color: "black" }}>All Vehicles</span>
                      </NavLink>
                    </li> */}
                    {/*<li className='dropdown' onClick={this.scrollOnclick}>
                      <Link
                        onClick={() => this.props.turnOffHomefilter()}
                        to={"/stock"}
                        className='dropdown-toggle'
                      >
                        <span>Used Cars</span>
                      </Link>
                    </li>*/}
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink to={"/gwm-range"}>
                        <span style={{ color: "black" }}> New Vehicles</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink to={"/stock"}>
                        <span style={{ color: "black" }}> Used Vehicles</span>
                      </NavLink>
                    </li>
                    <li
                      className="dropdown"
                      onClick={this.scrollOnclick}
                      style={{ color: "white", paddingTop: "3px" }}
                    >
                      <span style={{ color: "black" }}>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="Book a Service"
                                onClick={this.showService}
                              >
                                Book a Service
                              </Menu.Item>
                              <Menu.Item
                                key="Book a Test Drive"
                                onClick={this.showTestDrive}
                              >
                                Book a Test Drive
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <span>
                            Bookings <DownOutlined />
                          </span>
                        </Dropdown>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="col-md-2 col-lg-2">
                  <span className="logo" onClick={this.scrollOnclickHome}>
                    <Link to={"/"} className="dropdown-toggle">
                      <img
                        style={{ filter: "invert(1)" }}
                        src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                        alt="logo"
                      />
                    </Link>
                  </span>
                </div>
                <div className="col-sm-5 col-md-5 col-lg-5">
                  <ul className="pull-right">
                    {/* <li className='dropdown' onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName='active'
                        to={"/about-kia"}

                      >
                        <span style={{ color: "black" }}> About Kia</span>
                      </NavLink>
                    </li> */}
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName="active"
                        to={"/promotions"}
                        className="lrg-logo"
                      >
                        <span style={{ color: "black" }}> Promotions</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName="active"
                        to={"/blogs"}
                        className="lrg-logo"
                      >
                        <span style={{ color: "black" }}> Blogs</span>
                      </NavLink>
                    </li>
                    <li className="dropdown" onClick={this.scrollOnclick}>
                      <NavLink
                        activeClassName="active"
                        to={"/contactUs"}
                        className="lrg-logo"
                      >
                        <span style={{ color: "black" }}> Get in Touch</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="search-box">
          <i className="fa fa-search" />
          <form>
            <input
              type="text"
              name="search-txt"
              placeholder="Search..."
              className="search-txt form-item"
            />
            <button type="submit" name="submit" className="search-btn btn-1">
              <i className="fa fa-search" />
            </button>
          </form>
        </div>*/}
          {/* <span className='btn fav' href='#'>
            <li className='cart-icon'>
              <Link to={"/wishlist"} style={{ color: "black" }}>
                <i
                  className={
                    this.props.wishlistproducts.length > 0
                      ? "fa fa-heart"
                      : "fa fa-heart-o"
                  }
                />{" "}
                Favourite

              </Link>
            </li>
          </span> */}
          {/* <span className='btn get-in-touch' href='#'>
            <li className='cart-icon' onClick={this.scrollOnclick}>
              <Link to={"/contactUs"} style={{ color: "black" }}>
                <i className={"fa fa-phone"} /> Get in Touch
              </Link>
            </li>
          </span> */}
        </header>
        {/*mobile-view*/}
        <header id="wrap-header" className="mobile-view">
          <div className="top-header top-header-lrg">
            <div className="row">
              <div className="col-md-2 col-lg-2">
                <span className="logo" onClick={this.scrollOnclickHome}>
                  <Link to={"/"} className="dropdown-toggle">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                    />
                  </Link>
                </span>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10">
                <Menu1
                  right
                  isOpen={this.state.menuOpen}
                  onStateChange={state => this.handleStateChange(state)}
                >
                  <div className="banner-item-menu banner-bg-4">
                    <div className="row text-align-lg-center ">
                      <div className="col-md-12 col-lg-12">
                        <span
                          className="logo-menu"
                          onClick={() => this.closeMenu()}
                        >
                          <Link to={"/"} className="dropdown-toggle">
                            <img
                              className="logo-menu"
                              src={`${process.env.PUBLIC_URL}/images/logo-black.png`}
                              alt="logo"
                            />
                          </Link>
                        </span>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row text-align-lg-center color-black">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-black f-20"
                          onClick={() => this.closeMenu()}
                          to={"/stock"}
                        >
                          Showroom
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-black f-20"
                          to={"/bookService"}
                          onClick={() => this.closeMenu()}
                        >
                          Book a Service
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-black f-20"
                          to={"/sellacar"}
                          onClick={() => this.closeMenu()}
                        >
                          Sell your Car
                        </Link>
                      </div>
                    </div>
                    <br />
                    {/*<div className="row">
                      <div className="col-md-12 col-lg-12">
                      <li className="dropdown" onClick={this.scrollOnclick}>
                          <Dropdown overlay={menu}>
                            <a
                              className="icon-1"
                              onClick={(e) => e.preventDefault()}
                            >
                              <span>
                                Branches <DownOutlined />
                              </span>
                            </a>
                          </Dropdown>
                        </li>
                      </div>
                    </div>*/}
                    {/*<div className='row text-align-lg-center'>
                      <div className='col-md-12 col-lg-12'>
                        <Link
                          className='color-black f-20'
                          to={"/sellacar"}
                          onClick={() => this.closeMenu()}
                        >
                          Financing
                        </Link>
                      </div>
                    </div>
                    <br />*/}
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-black f-20"
                          to={"/blogs"}
                          onClick={() => this.closeMenu()}
                        >
                          Blogs
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          to={"/downloadCenter"}
                          className="color-black f-20"
                          onClick={() => this.closeMenu()}
                        >
                          <span>Download Center</span>
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          to={"/Specials"}
                          className="color-black f-20"
                          onClick={() => this.closeMenu()}
                        >
                          <span>Specials</span>
                        </Link>
                      </div>
                    </div>
                    <br />
                    <div className="row text-align-lg-center">
                      <div className="col-md-12 col-lg-12">
                        <Link
                          className="color-black f-20"
                          to={"/contactUs"}
                          onClick={() => this.closeMenu()}
                        >
                          Get in Touch
                        </Link>
                      </div>
                    </div>
                    <br />{" "}
                    {/*<div className="row">
                      <div className="col-md-12 col-lg-12">
                        <li onClick={this.scrollOnclick}>
                          <span className="badge header-badge">
                            Launching Soon
                          </span>
                          <Link
                            style={{
                              marginTop: "0.7em",
                              backgroundColor: "black",
                              padding: "0.7em 2em 0.7em 2em",
                              color: "black",
                              fontWeight: 500,
                              borderRadius: "3em",
                            }}
                          >
                            <span>
                              <i
                                className="fa fa-shopping-cart"
                                aria-hidden="true"
                              ></i>{" "}
                              Shop
                            </span>
                          </Link>{" "}
                        </li>
                      </div>
                    </div>*/}
                  </div>
                </Menu1>{" "}
                {this.props.wishlistproducts.length > 0 ? (
                  <Link
                    style={{
                      top: 70,
                      right: 0,
                      position: "absolute",
                      marginTop: "0.7em",
                      backgroundColor: "black",
                      padding: "0.7em 1em 0.7em 0.7em",
                      color: "black",
                      fontWeight: 500,
                      borderRadius: "0.7em 1em 0.7em 0.7em",
                    }}
                    className="color-black f-20"
                    to={"/wishlist"}
                  >
                    <i
                      className={
                        this.props.wishlistproducts.length > 0
                          ? "fa fa-heart-o"
                          : "fa fa-heart-o"
                      }
                    />{" "}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </header>{" "}
        <div>
          <StyledModal
            title={
              <span>
                <img
                  style={{ width: "12em" }}
                  src={`${process.env.PUBLIC_URL}/images/logo.png`}
                  alt="logo"
                />
                <h3 className="f-18" style={{ marginBottom: "-1em" }}>
                  Finance Calculator
                </h3>
              </span>
            }
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <FinanceBox />{" "}
            <Collapse ghost>
              <Panel header="Disclaimer" key="1">
                <p>{text}</p>
              </Panel>
            </Collapse>
          </StyledModal>
        </div>
        <Modal
          title="Basic Modal"
          visible={this.state.visibleService}
          onOk={this.handleOkService}
          onCancel={this.handleCancelService}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
        <Modal
          title="Basic Modal"
          visible={this.state.visibleTestDrive}
          onOk={this.handleOkTestDrive}
          onCancel={this.handleCancelTestDrive}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  turnOffHomefilter,
  turnOnHomefilter,
})(Header);
