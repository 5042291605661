import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProducts } from "../../redux/actions";

import ProductsContainer from "./ProductsContainer";
import ProductDetails from "./ProductDetails";

class ProductsShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: "TYRES&WHEELS",
    };
  }

  componentDidMount() {
    this.props.fetchProducts("TYRES&WHEELS");
  }

  renderFiltredProducts(activePage) {
    this.setState({ activePage });
    this.props.fetchProducts(activePage);
  }

  render() {
    return (
      <div
        className='wrap-body-inner'
        style={
          {
            // minHeight: "1200px"
          }
        }
      >
        {" "}
        <img
          //className="banner-img-home"
          style={{
            width: "106vw",
            position: "absolute",
            height: "35em",
            objectFit: "cover",
            marginTop: "0em",
            right: 0,
            zIndex: 0,
          }}
          src={`${process.env.PUBLIC_URL}/images/shop-banner.png`}
          alt='logo'
        />
        <div className='row m-b-lg-80 '>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='banner-item-home banner-2x-home no-bg color-inher-home text-align-lg-right '>
              <h1 style={{ color: "white" }} className='f-weight-400 f-20'>
                QUALITY BEYOND EXCELLENCE
              </h1>
              <h1 style={{ color: "white" }} className='f-weight-600 f-50'>
                BUY TRUSTWORTHY <br />
              </h1>
              <h1
                style={{ color: "red", textTransform: "none" }}
                className='f-weight-400 f-50'
              >
                STOCK ACCESSORIES
              </h1>

              <p style={{ color: "white" }}>
                Mega Theron makes it easy to get the parts&Service your stock
                deserves, at the best prices possible.
              </p>
              <a
                href='#shop'
                className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                style={{ textTransform: "none" }}
              >
                Shop Now
              </a>
              {/*</div>*/}
            </div>
          </div>
        </div>
        <section id='shop' className='m-t-lg-30 m-t-xs-0 m-t-lg-50'>
          <div className='row'>
            <div className='col-sm-4 col-md-3 col-lg-3'>
              <div className='category m-b-lg-50'>
                <div className='heading m-b-lg-0'>
                  <h3
                    className='p-l-lg-20'
                    data-toggle='collapse'
                    data-target='#collapseExample'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <i
                      className='fa fa-bars f-19'
                      //style={{ marginTop: "-0.2em" }}
                    ></i>
                    Category
                  </h3>
                </div>
                <ul className='collapse in' id='collapseExample'>
                  <li
                    className={
                      this.state.activePage === "TYRES & WHEELS" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => this.renderFiltredProducts("TYRES&WHEELS")}
                    >
                      TYRES&WHEELS
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.activePage === "BRAKES&DISCS" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => this.renderFiltredProducts("BRAKES&DISCS")}
                    >
                      Brakes discs
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Exhaust tips
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Car covers
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Seat covers
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Mirrors<i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Bumpers<i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Floor mats
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Calipers<i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      Hand brakes
                      <i className='fa fa-chevron-right pull-right'></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='banner-item banner-bg-4 banner-1x color-inher'>
                <h5>GREAT ATTENTION TO DETAIL</h5>
                <h3 className='f-weight-300'>
                  <strong>Stock Service</strong> And Repair
                </h3>
                <p>Car in need of a service? Book one now.</p>
                <a className='ht-btn ht-btn-default'>Book now</a>
              </div>
            </div>
            <BrowserRouter>
              <div>
                <Route
                  exact
                  path='/products'
                  render={() => (
                    <ProductsContainer
                      productsCategory={this.state.activePage}
                      productsList={this.props.products}
                    />
                  )}
                />
                <Route
                  exact
                  path='/products/vehicle-details'
                  component={ProductDetails}
                />
              </div>
            </BrowserRouter>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps({ products }) {
  return { products };
}

export default connect(mapStateToProps, { fetchProducts })(ProductsShop);
